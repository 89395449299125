import React, { Fragment, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { MdHistory } from "react-icons/md";
import moment from "moment";
import CustomersDetailsContext from "../context";

const Inactivations = () => {
  const { formData } = useContext(CustomersDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      {_.size(formData.inactivations) > 0 && <IconButton variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpen} />}
      <Modal size="lg" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inativações</ModalHeader>
          <Divider />
          <ModalBody as={VStack} alignItems="stretch" spacing={4} divider={<StackDivider />} p="20px">
            {_.map(formData.inactivations, (item) => (
              <Box>
                <Text fontSize="xs">
                  Criado por <strong>{item.createdBy?.name || "-"}</strong> em{" "}
                  <strong>{moment(item.createdAt).format("DD/MM/YYYY")}</strong> às{" "}
                  <strong>{moment(item.createdAt).format("HH:mm:ss")}</strong>
                </Text>
                <Text fontSize="sm">{item.comments}</Text>
              </Box>
            ))}
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
export default Inactivations;
