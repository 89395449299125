import React, { useCallback, useContext, useState } from "react";
import _ from "lodash";
import { Box, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react";
import { CustomerLogo, FileUpload } from "components";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { MdEdit } from "react-icons/md";
import CustomersDetailsContext from "./context";
import { useParams } from "react-router-dom";

const Logo = () => {
  const { _id } = useParams();
  const { formData, isLoadingData, refreshData } = useContext(CustomersDetailsContext);
  const { isOpen: isOpenLogoChange, onOpen: onOpenLogoChange, onClose: onCloseLogoChange } = useDisclosure();
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const toast = useCustomToast();

  const handleDeleteLogo = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete(`files/${formData.logo?._id}`);
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
    }
  }, [formData.logo?._id, refreshData]);

  return (
    <>
      <Box position="relative">
        <CustomerLogo alt={formData.tradingName} src={formData.logo?.thumb} />
        {_id && (
          <Menu>
            <MenuButton
              as={IconButton}
              size="xs"
              colorScheme="main"
              position="absolute"
              right="-5px"
              bottom="-5px"
              borderRadius="full"
              icon={<Icon as={MdEdit} />}
              isLoading={isLoadingData || isLoadingDeleteData}
            />
            <MenuList fontSize="xs">
              <MenuItem onClick={onOpenLogoChange}>Carregar imagem</MenuItem>
              <MenuItem onClick={handleDeleteLogo} isDisabled={!formData.logo?._id}>
                Remover foto
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Box>
      <FileUpload
        path="/files"
        group="supplierLogo"
        ownerRef="Customer"
        owner={_id}
        isOpen={isOpenLogoChange}
        onClose={onCloseLogoChange}
        onChange={refreshData}
      />
    </>
  );
};

export default Logo;
