import * as processors from "./processors";

const defaultRows = [
  { key: "consultant", title: "Consultor", processor: processors.consultant, isActive: true },
  { key: "customer", title: "Cliente", processor: processors.customer, isActive: true },
  { key: "meetingRoom", title: "Sala de reunião", processor: processors.meetingRoom },
  { key: "meetingType", title: "Tipo de reunião", processor: processors.meetingType },
  { key: "demand", title: "Demanda", processor: processors.demand },
  { key: "team", title: "Time", processor: processors.team },
  { key: "activityType", title: "Tipo de atividade", processor: processors.activityType },
];

export default defaultRows;
