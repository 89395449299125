import React, { Fragment, useContext, useMemo, useState } from "react";
import _ from "lodash";
import { Box, Center, HStack, Icon, IconButton, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useToken } from "@chakra-ui/react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { colorScale, percent, translator } from "lib";
import { statuses } from "consts";
import { MdOutlineVisibility } from "react-icons/md";
import { ActivitiesContext } from "./index";
import SearchInput from "../components/SearchInput";
import SortButton from "../components/SortButton";
import { Team } from "./classes/Team";
import { useSearch } from "hooks";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: { position: "top" },
    title: { display: false },
  },
};

const recoloredStatuses = _.map(statuses.tasks, (o) => (o.value === "pending" ? "red.500" : o.color));

const ByTeams = () => {
  const { query, activities, isLoadingActivities, handleNavigate } = useContext(ActivitiesContext);
  const colors = useToken("colors", recoloredStatuses);
  const data = useMemo(() => {
    const response = {};
    for (const activity of activities || []) {
      const teams = (() => {
        if (activity.moduleName === "tasks") return [activity.team];
        return activity.meetingType.teams;
      })();
      for (const team of teams) {
        response[team._id] = response[team._id] || new Team(team);
        response[team._id].incQuantity();
        response[team._id].incStatusQuantity(activity.ustatus);
      }
    }
    return Object.values(response);
  }, [activities]);
  const charts = useMemo(() => {
    const datasets = {};
    for (const item of data) {
      for (let index = 0; index < statuses.tasks.length; index++) {
        const { value } = statuses.tasks[index];
        if (datasets[value]) datasets[value].data.push(item.statuses[value].quantity);
        else datasets[value] = { label: translator(value), data: [item.statuses[value].quantity], backgroundColor: colors[index] };
      }
    }
    return { labels: data.map((o) => o.title), datasets: Object.values(datasets) };
  }, [data]);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState({ key: "title", direction: "asc" });
  const searched = useSearch(useMemo(() => ({ keys: ["title"], data, searchText }), [data, searchText]));
  const sorted = useMemo(() => _.orderBy(searched, [sort.key], [sort.direction]), [searched, sort]);

  return (
    <>
      <Box borderWidth="1px" borderRadius="lg" overflowX="auto" mb={2}>
        <SearchInput value={searchText} onSubmit={(value) => setSearchText(value)} />
        <Table size="sm">
          <Thead>
            <Tr>
              <Th rowSpan={2} textTransform="none" fontSize="xs">
                <HStack>
                  <Text>Time</Text>
                  <SortButton keyName="title" {...{ sort, setSort }} />
                </HStack>
              </Th>
              <Th rowSpan={2} textTransform="none" fontSize="xs" borderLeftWidth="1px">
                <HStack justifyContent="center">
                  <Text>Qtd</Text>
                  <SortButton keyName="quantity" {...{ sort, setSort }} />
                </HStack>
              </Th>
              {_.map(statuses.tasks, ({ color, value }) => (
                <Th key={value} colSpan={2} borderBottomWidth="0" textTransform="none" fontSize="xs" borderLeftWidth="1px">
                  <HStack justifyContent="center">
                    <Box w="10px" h="10px" borderRadius="10px" bg={value === "pending" ? "red.500" : color} />
                    <Text>{translator(value)}</Text>
                  </HStack>
                </Th>
              ))}
            </Tr>
            <Tr>
              {statuses.tasks.map(({ value }) => (
                <Fragment key={value}>
                  <Th textTransform="none" textAlign="center" fontSize="xs" borderLeftWidth="1px">
                    <HStack justifyContent="center">
                      <Text>Qtd</Text>
                      <SortButton keyName={`statuses.${value}.quantity`} {...{ sort, setSort }} />
                    </HStack>
                  </Th>
                  <Th textTransform="none" textAlign="center" fontSize="xs">
                    <HStack justifyContent="center">
                      <Text>%</Text>
                      <SortButton keyName={`statuses.${value}.percentage`} {...{ sort, setSort }} />
                    </HStack>
                  </Th>
                </Fragment>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {_.map(sorted, (item) => (
              <Tr key={item._id} _hover={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}>
                <Td fontSize="xs" textAlign="left">
                  {item.title}
                </Td>
                <Td fontSize="xs" textAlign="center" borderLeftWidth="1px">
                  {item.quantity}
                </Td>
                {_.map(statuses.tasks, ({ color, value }) => {
                  const props = value === "pending" && colorScale(item.statuses[value].percentage);
                  const buttonProps = value === "pending" ? { variant: "solid", colorScheme: "whiteAlpha" } : { variant: "outline" };
                  return (
                    <Fragment key={value}>
                      <Td fontSize="xs" textAlign="center" borderLeftWidth="1px" {...props}>
                        <HStack justifyContent="center">
                          <Text>{item.statuses[value].quantity.toLocaleString()}</Text>
                          <IconButton
                            size="xs"
                            {...buttonProps}
                            color={props.color}
                            icon={<Icon as={MdOutlineVisibility} />}
                            onClick={() => {
                              for (const moduleName of query.moduleNames)
                                handleNavigate(moduleName.value, {
                                  team: [{ _id: item._id, title: item.title }],
                                  status: [{ color, value }],
                                });
                            }}
                          />
                        </HStack>
                      </Td>
                      <Td fontSize="xs" textAlign="center" {...props}>
                        {percent.format(item.statuses[value].percentage)}
                      </Td>
                    </Fragment>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
        {isLoadingActivities && (
          <Center p="40px">
            <Spinner />
          </Center>
        )}
      </Box>
      <Box borderWidth="1px" borderRadius="lg" p="20px">
        <Bar options={options} data={charts} />
      </Box>
    </>
  );
};

export default ByTeams;
