import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AsyncSelect, CustomerLogo, RangeDatePicker, SyncSelect } from "components";
import { useCacheState, useStickyState } from "hooks";
import { api, translator } from "lib";
import { statuses } from "consts";
import { MdClose, MdRefresh, MdSearch } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

let loadUsersTimeout = {};
let loadCustomersTimeout, loadSuppliersTimeout, loadChartOfAccountsTimeout, loadCostCentersTimeout, loadCompaniesTimeout;

const categories = [{ value: "fixed" }, { value: "variable" }];

const Filters = ({ onQuery, onSearch, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: location.state ?? { status: statuses.bills.filter((o) => o.value === "pending") },
        useCached: _.isObject(location.state) === false,
        _v: 1,
        processor: (data) => ({
          ...data,
          dueDateStartDate: data.dueDateStartDate && moment(data.dueDateStartDate).toDate(),
          dueDateEndDate: data.dueDateEndDate && moment(data.dueDateEndDate).toDate(),
          paidAtStartDate: data.paidAtStartDate && moment(data.paidAtStartDate).toDate(),
          paidAtEndDate: data.paidAtEndDate && moment(data.paidAtEndDate).toDate(),
          finishedAtStartDate: data.finishedAtStartDate && moment(data.finishedAtStartDate).toDate(),
          finishedAtEndDate: data.finishedAtEndDate && moment(data.finishedAtEndDate).toDate(),
          createdAtStartDate: data.createdAtStartDate && moment(data.createdAtStartDate).toDate(),
          createdAtEndDate: data.createdAtEndDate && moment(data.createdAtEndDate).toDate(),
        }),
      }),
      [location.pathname, location.state]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    if (query.status?.length) _.set(response, "status.$in", _.map(query.status, "value"));

    const entity = [];
    if (query.customer?.length) _.forEach(query.customer, (customer) => entity.push(customer));
    if (query.supplier?.length) _.forEach(query.supplier, (supplier) => entity.push(supplier));
    if (query.user?.length) _.forEach(query.user, (user) => entity.push(user));
    if (entity.length) _.set(response, "entity.$in", _.map(entity, "_id"));

    if (query.chartOfAccount?.length) _.set(response, "chartOfAccount.$in", _.map(query.chartOfAccount, "_id"));
    if (query.costCenter?.length) _.set(response, "costCenter.$in", _.map(query.costCenter, "_id"));
    if (query.company?.length) _.set(response, `["allocation.company"].$in`, _.map(query.company, "_id"));
    if (query.dueDateStartDate) _.set(response, "dueDate.$gte", query.dueDateStartDate);
    if (query.dueDateEndDate) _.set(response, "dueDate.$lte", query.dueDateEndDate);
    if (query.paidAtStartDate) _.set(response, "paymentDate.$gte", query.paidAtStartDate);
    if (query.paidAtEndDate) _.set(response, "paymentDate.$lte", query.paidAtEndDate);
    if (query.finishedBy?.length) _.set(response, "finishedBy.$in", _.map(query.finishedBy, "_id"));
    if (query.finishedAtStartDate) _.set(response, "dueDate.$gte", query.finishedAtStartDate);
    if (query.finishedAtEndDate) _.set(response, "dueDate.$lte", query.finishedAtEndDate);
    if (query.createdBy?.length) _.set(response, "createdBy.$in", _.map(query.createdBy, "_id"));
    if (query.createdAtStartDate) _.set(response, "createdAt.$gte", query.createdAtStartDate);
    if (query.createdAtEndDate) _.set(response, "createdAt.$lte", query.createdAtEndDate);
    if (query.isDelayed?.length) _.set(response, "isDelayed", query.isDelayed === "yes");
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const [{ value }] = e.target;
    onSearch(value);
    onPage(0);
  }, []);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { status: { $ne: "inactive" } },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/customers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadSuppliers = useCallback((search, cb) => {
    clearTimeout(loadSuppliersTimeout);
    loadSuppliersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { status: { $ne: "inactive" } },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/suppliers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((key, search, cb) => {
    clearTimeout(loadUsersTimeout[key]);
    loadUsersTimeout[key] = setTimeout(async () => {
      const params = {
        search,
        query: { isActive: true },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/users", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadChartOfAccounts = useCallback((search, cb) => {
    clearTimeout(loadChartOfAccountsTimeout);
    loadChartOfAccountsTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { detailing: "analytical", isActive: true },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/accounts", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadCostCenters = useCallback((search, cb) => {
    clearTimeout(loadCostCentersTimeout);
    loadCostCentersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { isActive: true },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/cost-centers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadCompanies = useCallback((search, cb) => {
    clearTimeout(loadCompaniesTimeout);
    loadCompaniesTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { isActive: true },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/companies", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={8}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <form onSubmit={handleSearch}>
            <InputGroup width="100%">
              <Input variant="filled" placeholder="Pesquisar..." />
              <InputRightElement>
                <Icon as={MdSearch} />
              </InputRightElement>
            </InputGroup>
          </form>
        </GridItem>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 8 }}>
          <HStack>
            <Button
              colorScheme={isFiltering ? "main" : "gray"}
              variant="outline"
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Categoria
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.category ?? []}
                  placeholder="Selecione"
                  options={categories}
                  onChange={(category) => setFormData((state) => ({ ...state, category }))}
                  formatOptionLabel={({ value }) => translator(value)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.bills}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cliente
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customer ?? []}
                  defaultOptions
                  loadOptions={handleLoadCustomers}
                  placeholder="Selecione"
                  onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                    <HStack>
                      <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                      <Box flex="1">
                        <Text fontSize="sm" noOfLines={1}>
                          {tradingName}
                        </Text>
                        <Text fontSize="xs">{segment?.title || "-"}</Text>
                      </Box>
                    </HStack>
                  )}
                  isDisabled={_.isObject(location.state?.customer)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Fornecedor
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.supplier ?? []}
                  defaultOptions
                  loadOptions={handleLoadSuppliers}
                  placeholder="Selecione"
                  onChange={(supplier) => setFormData((state) => ({ ...state, supplier }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                    <HStack>
                      <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                      <Box flex="1">
                        <Text fontSize="sm" noOfLines={1}>
                          {tradingName}
                        </Text>
                        <Text fontSize="xs">{segment?.title || "-"}</Text>
                      </Box>
                    </HStack>
                  )}
                  isDisabled={_.isObject(location.state?.supplier)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Usuário
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.user ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("entity", search, cb)}
                  placeholder="Selecione"
                  onChange={(user) => setFormData((state) => ({ ...state, user }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ avatarUrl, name }) => (
                    <HStack>
                      <Avatar size="xs" name={name} src={avatarUrl} />
                      <Text>{name}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Plano de contas
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.chartOfAccount ?? []}
                  defaultOptions
                  loadOptions={handleLoadChartOfAccounts}
                  placeholder="Selecione"
                  onChange={(chartOfAccount) => setFormData((state) => ({ ...state, chartOfAccount }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ code, name }) => `${code} - ${name}`}
                  isDisabled={_.isObject(location.state?.chartOfAccount)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Centro de custo
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.costCenter ?? []}
                  defaultOptions
                  loadOptions={handleLoadCostCenters}
                  placeholder="Selecione"
                  onChange={(costCenter) => setFormData((state) => ({ ...state, costCenter }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                  isDisabled={_.isObject(location.state?.costCenter)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Empresas
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.company ?? []}
                  defaultOptions
                  loadOptions={handleLoadCompanies}
                  placeholder="Selecione"
                  onChange={(company) => setFormData((state) => ({ ...state, company }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ tradingName }) => tradingName}
                  isDisabled={_.isObject(location.state?.company)}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Atrasado?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isDelayed ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isDelayed: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Data de vencimento
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.dueDateStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.dueDateStartDate, formData.dueDateEndDate]}
                    onDateChange={([dueDateStartDate, dueDateEndDate]) =>
                      setFormData((state) => ({ ...state, dueDateStartDate, dueDateEndDate }))
                    }
                  />
                  {formData.dueDateStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          dueDateStartDate: null,
                          dueDateEndDate: null,
                          dueDateStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Data de pagamento
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.paidAtStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.paidAtStartDate, formData.paidAtEndDate]}
                    onDateChange={([paidAtStartDate, paidAtEndDate]) =>
                      setFormData((state) => ({ ...state, paidAtStartDate, paidAtEndDate }))
                    }
                  />
                  {formData.paidAtStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          paidAtStartDate: null,
                          paidAtEndDate: null,
                          paidAtStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Finalizada por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.finishedBy ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("finishedBy", search, cb)}
                  placeholder="Selecione"
                  onChange={(finishedBy) => setFormData((state) => ({ ...state, finishedBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ avatarUrl, name }) => (
                    <HStack>
                      <Avatar size="xs" name={name} src={avatarUrl} />
                      <Text>{name}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Finalizada em
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.closedAtStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.finishedAtStartDate, formData.finishedAtEndDate]}
                    onDateChange={([finishedAtStartDate, finishedAtEndDate]) =>
                      setFormData((state) => ({ ...state, finishedAtStartDate, finishedAtEndDate }))
                    }
                  />
                  {formData.finishedAtStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          finishedAtStartDate: null,
                          finishedAtEndDate: null,
                          closedAtStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criada por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.createdBy ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("createdBy", search, cb)}
                  placeholder="Selecione"
                  onChange={(createdBy) => setFormData((state) => ({ ...state, createdBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ avatarUrl, name }) => (
                    <HStack>
                      <Avatar size="xs" name={name} src={avatarUrl} />
                      <Text>{name}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.createdAtStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.createdAtStartDate, formData.createdAtEndDate]}
                    onDateChange={([createdAtStartDate, createdAtEndDate]) =>
                      setFormData((state) => ({ ...state, createdAtStartDate, createdAtEndDate }))
                    }
                  />
                  {formData.createdAtStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStartDate: null,
                          createdAtEndDate: null,
                          createdAtStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
