import React, { useContext, useState, useCallback, useMemo, Fragment } from "react";
import { useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import { PermissionedContainer } from "components";
import TasksDetailsContext from "./context";
import { RxReset } from "react-icons/rx";

const Reset = () => {
  const { _id } = useParams();
  const { refreshData } = useContext(TasksDetailsContext);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useCustomToast();

  const handleSaveData = useCallback(async () => {
    try {
      setIsLoadingSaveData(true);
      await api.patch(`/meetings/${_id}/reset`);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
      onClose();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [_id, refreshData, toast, onClose]);

  return (
    <Fragment>
      <PermissionedContainer required="meetings:reset">
        <Button size="sm" variant="outline" leftIcon={<Icon as={RxReset} />} onClick={onOpen}>
          resetar reunião
        </Button>
      </PermissionedContainer>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>
            <Text>Deseja realmente resetar a reunião atual?</Text>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSaveData}>
              confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

export default Reset;
