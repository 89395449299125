import * as yup from "yup";
import { messages } from "consts";

yup.addMethod(yup.string, "isValidName", function (errorMessage) {
  return this.test("is-valid-name", errorMessage, function (value) {
    const { path, createError } = this;
    try {
      const values = value.trim().split(" ");
      if (values.length < 2) throw new Error();
      const letters = /^[A-Za-zÀ-ú]+$/;
      for (const value of values) {
        if (!value.match(letters)) throw new Error();
      }
      return true;
    } catch (error) {
      return createError({ path, message: messages.error.invalidName });
    }
  });
});

yup.addMethod(yup.date, "isValidPauseStartedAt", function (data) {
  return this.test("is-valid-pause-started-at", null, function (startedAt) {
    const { path, createError } = this;
    try {
      if (startedAt <= data.startedAt) throw new Error();
      return true;
    } catch (error) {
      return createError({ path, message: messages.error.invalidPauseStartedAt });
    }
  });
});

yup.addMethod(yup.date, "isValidPauseFinishedAt", function (data) {
  return this.test("is-valid-pause-finished-at", null, function (finishedAt) {
    const { path, createError } = this;
    try {
      if (finishedAt && data.finishedAt && finishedAt >= data.finishedAt) throw new Error();
      return true;
    } catch (error) {
      return createError({ path, message: messages.error.invalidPauseFinishedAt });
    }
  });
});

yup.addMethod(yup.string, "isValidNotes", function (errorMessage) {
  return this.test("is-valid-notes", errorMessage, function (value) {
    const { path, createError } = this;
    try {
      if (value.length < 20) throw new Error("A nota deve conter pelo menos 20 caracteres.");
      const words = value ? value.split(" ") : [];
      if (new Set(words).size < 3) throw new Error("A nota deve conter pelo menos 3 palavras distintas.");
      return true;
    } catch (error) {
      return createError({ path, message: error.message });
    }
  });
});

export default yup;
