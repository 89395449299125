import React, { useContext, useMemo, useState } from "react";
import _ from "lodash";
import { Box, Td, Table, Tbody, Th, Thead, Tr, Center, Spinner, HStack, Text, IconButton, Icon } from "@chakra-ui/react";
import { formatMinutes, percent } from "lib";
import { statuses } from "consts";
import SearchInput from "../components/SearchInput";
import { ActivitiesContext } from "./index";
import { MdOutlineVisibility } from "react-icons/md";
import SortButton from "../components/SortButton";
import { Consultant } from "./classes/Consultant";
import { useSearch } from "hooks";

const exceptCanceledStatuses = statuses.tasks.filter((o) => o.value !== "canceled");

const ByConsultant = () => {
  const { query, activities, isLoadingActivities, totalMinutesInMonth, handleNavigate } = useContext(ActivitiesContext);
  const data = useMemo(() => {
    const response = {};
    const createOrUpdateConsultant = (entity, durationInMinutes) => {
      const tmp = response[entity._id] || new Consultant(entity, totalMinutesInMonth);
      tmp.incQuantity();
      tmp.incDuration(durationInMinutes);
      response[entity._id] = tmp;
    };
    for (const { moduleName, ustatus, ...activity } of activities || []) {
      if (ustatus === "canceled") continue;
      if (moduleName === "tasks") {
        if (!activity.responsible) continue;
        createOrUpdateConsultant(activity.responsible, activity.demand.informedAvgDurationInMinutes);
      } else for (const participant of activity.participants) createOrUpdateConsultant(participant.user, activity.durationInMinutes);
    }
    return Object.values(response);
  }, [activities, totalMinutesInMonth]);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState({ key: "name", direction: "asc" });
  const searched = useSearch(useMemo(() => ({ keys: ["name"], data, searchText }), [data, searchText]));
  const sorted = useMemo(() => _.orderBy(searched, [sort.key], [sort.direction]), [searched, sort]);

  return (
    <Box borderWidth="1px" borderRadius="lg" overflowX="auto">
      <SearchInput value={searchText} onSubmit={(value) => setSearchText(value)} />
      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th textTransform="none" fontSize="xs">
              <HStack>
                <Text>Consultor</Text>
                <SortButton keyName="name" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Text>Qtd atividades</Text>
                <SortButton keyName="quantity" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Text>Tempo médio</Text>
                <SortButton keyName="avgDuration" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Text>Tempo empenhado</Text>
                <SortButton keyName="duration" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Text>Capacidade empenhada</Text>
                <SortButton keyName="percentage" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Text>Tempo restante</Text>
                <SortButton keyName="remainingDuration" {...{ sort, setSort }} />
              </HStack>
            </Th>
            <Th textTransform="none" fontSize="xs" textAlign="center">
              <HStack justifyContent="center">
                <Text>Capacidade restante</Text>
                <SortButton keyName="remainingPercentage" {...{ sort, setSort }} />
              </HStack>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(sorted, (item) => (
            <Tr key={item._id} _hover={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}>
              <Td fontSize="xs">{item.name}</Td>
              <Td fontSize="xs" textAlign="center">
                <HStack justifyContent="center">
                  <Text>{item.quantity}</Text>
                  <IconButton
                    size="xs"
                    variant="outline"
                    icon={<Icon as={MdOutlineVisibility} />}
                    onClick={() => {
                      for (const moduleName of query.moduleNames)
                        handleNavigate(moduleName.value, {
                          responsible: [{ _id: item._id, name: item.name }],
                          status: exceptCanceledStatuses,
                        });
                    }}
                  />
                </HStack>
              </Td>
              <Td fontSize="xs" textAlign="center">
                {formatMinutes(item.avgDuration)}
              </Td>
              <Td fontSize="xs" textAlign="center">
                {formatMinutes(item.duration)}
              </Td>
              <Td
                fontSize="xs"
                textAlign="center"
                bg={item.percentage > 0.9 && "red.100"}
                color={(item.percentage > 0.9 || item.percentage < 0) && "red.500"}
              >
                {percent.format(item.percentage)}
              </Td>
              <Td fontSize="xs" textAlign="center" color={item.remainingDuration < 0 && "red.500"}>
                {formatMinutes(item.remainingDuration)}
              </Td>
              <Td
                fontSize="xs"
                textAlign="center"
                bg={item.remainingPercentage > 0.9 && "red.100"}
                color={(item.remainingPercentage > 0.9 || item.remainingPercentage < 0) && "red.500"}
              >
                {percent.format(item.remainingPercentage)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoadingActivities && (
        <Center p="40px">
          <Spinner />
        </Center>
      )}
    </Box>
  );
};

export default ByConsultant;
