import React, { memo, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { PermissionedContainer } from "components";
import { TbCalendar, TbClockHour3 } from "react-icons/tb";
import { MdDeleteOutline, MdOutlineMeetingRoom } from "react-icons/md";
import { MeetingsGridContext } from "..";
import { statuses } from "consts";

const placements = {
  internal_remote: "Online",
  internal_in_person: "Pres.",
  external: "Ext.",
};

const Card = memo(
  ({
    _id,
    status,
    placement,
    meetingType,
    mainMeetingRoom,
    customer,
    participants,
    customerParticipants,
    startDate,
    endDate,
    isDelayed,
  }) => {
    const navigate = useNavigate();
    const { viewMode, setCheckeds, onOpenDeleteDialog } = useContext(MeetingsGridContext);
    const colorScheme = useMemo(() => {
      if (isDelayed && _.includes(["unscheduled", "scheduled"], status)) return "red";
      try {
        const [colorScheme] = statuses.meetings.find((o) => o.value === status).color.split(".");
        return colorScheme;
      } catch (error) {
        return "gray";
      }
    }, [status, isDelayed]);
    const [intensity, color] = useMemo(() => (status === "sent" ? ["500", "white"] : ["100", "gray.800"]), [status]);

    const handleDelete = useCallback(
      (e) => {
        e.stopPropagation();
        setCheckeds([_id]);
        onOpenDeleteDialog();
      },
      [_id, onOpenDeleteDialog]
    );

    return (
      <Box
        role="group"
        p="5px"
        position="relative"
        cursor="pointer"
        borderRadius="md"
        transition="200ms"
        borderWidth="1px"
        color={color}
        borderColor={`${colorScheme}.200`}
        backgroundColor={`${colorScheme}.${intensity}`}
        _active={{ opacity: 0.8 }}
        onClick={() => navigate(`/meetings/edit/${_id}`)}
      >
        <Text fontSize="x-small">{meetingType.title}</Text>
        <Text fontSize="x-small" fontWeight="semibold">
          {customer.tradingName}
        </Text>
        <Box
          position="absolute"
          bg="rgba(0,0,0,0.05)"
          bottom="2px"
          right="2px"
          opacity="1"
          transition="200ms"
          px="4px"
          borderRadius="md"
          _groupHover={{ opacity: 0 }}
          max={2}
        >
          <Text fontSize="x-small">{placements[placement]}</Text>
        </Box>
        <Text fontSize="x-small">{_.map(participants, "user.name").join(", ")}</Text>
        <HStack>
          <Box bg="blackAlpha.100" px="5px" borderRadius="sm">
            <Text fontSize="x-small">{_.map(customerParticipants, "name").join(", ")}</Text>
          </Box>
        </HStack>
        {mainMeetingRoom && (
          <HStack spacing="2px">
            <Icon as={MdOutlineMeetingRoom} boxSize="14px" />
            <Text fontSize="x-small" fontWeight="semibold" noOfLines={1}>
              {mainMeetingRoom?.title}
            </Text>
          </HStack>
        )}
        {viewMode === "kanban" && (
          <HStack spacing="2px">
            <Icon as={TbCalendar} boxSize="12px" />
            <Text fontSize="x-small" fontWeight="semibold">
              {moment(startDate).format("DD/MM/YYYY")}
            </Text>
          </HStack>
        )}
        <HStack spacing="2px">
          <Icon as={TbClockHour3} boxSize="12px" />
          <Text fontSize="x-small" fontWeight="semibold">
            {moment(startDate).format("HH:mm")} às {moment(endDate).format("HH:mm")}
          </Text>
        </HStack>
        {customer.classification && (
          <Box
            position="absolute"
            top="2px"
            right="2px"
            bg="whiteAlpha.600"
            borderRadius="lg"
            py="2px"
            px="5px"
            fontSize="x-small"
            transition="200ms"
            _groupHover={{ opacity: 0 }}
          >
            {customer.classification}
          </Box>
        )}
        <PermissionedContainer required="meetings:delete">
          <IconButton
            size="xs"
            variant="ghost"
            colorScheme={colorScheme}
            position="absolute"
            top="0"
            right="0"
            icon={<Icon as={MdDeleteOutline} />}
            transition="200ms"
            opacity="0"
            _groupHover={{ opacity: "1" }}
            onClick={handleDelete}
          />
        </PermissionedContainer>
      </Box>
    );
  }
);

export default Card;
