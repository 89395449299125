import React, { memo, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { PermissionedContainer } from "components";
import { TbCalendar } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import { TasksGridContext } from "..";
import { statuses } from "consts";

const Card = memo(({ _id, status, demand, customer, responsible, dueDate, isDelayed }) => {
  const navigate = useNavigate();
  const { viewMode, setCheckeds, onOpenDeleteDialog } = useContext(TasksGridContext);
  const colorScheme = useMemo(() => {
    if (isDelayed && _.includes(["unscheduled", "pending"], status)) return "red";
    try {
      const [colorScheme] = statuses.tasks.find((o) => o.value === status).color.split(".");
      return colorScheme;
    } catch (error) {
      return "gray";
    }
  }, [status, isDelayed]);

  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation();
      setCheckeds([_id]);
      onOpenDeleteDialog();
    },
    [_id, onOpenDeleteDialog]
  );

  return (
    <Box
      role="group"
      p="5px"
      position="relative"
      cursor="pointer"
      borderRadius="md"
      transition="200ms"
      borderWidth="1px"
      color="gray.800"
      borderColor={`${colorScheme}.200`}
      backgroundColor={`${colorScheme}.100`}
      _active={{ opacity: 0.8 }}
      onClick={() => navigate(`/tasks/edit/${_id}`)}
    >
      {customer.classification && (
        <Box
          position="absolute"
          top="2px"
          right="2px"
          bg="whiteAlpha.600"
          borderRadius="lg"
          py="2px"
          px="5px"
          fontSize="x-small"
          transition="200ms"
          _groupHover={{ opacity: 0 }}
        >
          {customer.classification}
        </Box>
      )}
      <PermissionedContainer required="tasks:delete">
        <IconButton
          size="xs"
          variant="ghost"
          colorScheme={colorScheme}
          position="absolute"
          top="0"
          right="0"
          icon={<Icon as={MdDeleteOutline} />}
          transition="200ms"
          opacity="0"
          _groupHover={{ opacity: "1" }}
          onClick={handleDelete}
        />
      </PermissionedContainer>
      <Text fontSize="x-small">{demand.title}</Text>
      <Text fontSize="x-small" fontWeight="semibold">
        {customer.tradingName}
      </Text>

      <Avatar
        position="absolute"
        bottom="2px"
        right="2px"
        size="2xs"
        opacity="1"
        transition="200ms"
        _groupHover={{ opacity: 0 }}
        borderColor={`${colorScheme}.100`}
        name={responsible.name}
        src={responsible.avatarUrl}
      />
      <Text fontSize="x-small">{responsible.name}</Text>
      {viewMode === "kanban" && (
        <HStack spacing="2px">
          <Icon as={TbCalendar} boxSize="12px" />
          <Text fontSize="x-small" fontWeight="semibold">
            {moment(dueDate).format("DD/MM/YYYY")}
          </Text>
        </HStack>
      )}
    </Box>
  );
});

export default Card;
