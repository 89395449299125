import React, { useContext, useEffect, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { Box, Button, Center, HStack, Heading, Icon, IconButton, Spinner, StackDivider, Text, VStack } from "@chakra-ui/react";
import { useApiGet } from "hooks";
import { MdRefresh } from "react-icons/md";
import { PiNotepadBold } from "react-icons/pi";
import { TbExternalLink } from "react-icons/tb";
import MeetingsDetailsContext from "../context";

const MeetingsNotes = () => {
  const { formData, setCounters, setIsLoadingCounters } = useContext(MeetingsDetailsContext);
  const [meetingsNotes, isLoadingMeetingsNotes, refreshMeetingsNotes] = useApiGet(
    useMemo(() => {
      const params = {
        query: { "customer._id": ["@ObjectId", formData.customer?._id], status: "finished", notes: { $exists: true, $ne: "" } },
        sort: { startDate: -1 },
        perPage: -1,
      };
      return { path: `/meetings`, params, options: { isEnabled: _.isString(formData.customer?._id) } };
    }, [formData.customer?._id])
  );

  useEffect(() => {
    setCounters((state) => ({ ...state, meetingsNotes: meetingsNotes?.size ?? 0 }));
  }, [setCounters, meetingsNotes?.size]);

  useEffect(() => {
    setIsLoadingCounters((state) => ({ ...state, meetingsNotes: isLoadingMeetingsNotes }));
  }, [setIsLoadingCounters, isLoadingMeetingsNotes]);

  return (
    <>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {meetingsNotes?.size ?? 0} registros adicionados
        </Text>
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={MdRefresh} />}
          isLoading={isLoadingMeetingsNotes}
          onClick={refreshMeetingsNotes}
        />
      </HStack>

      <VStack align="stretch" divider={<StackDivider />} spacing={4}>
        {_.map(meetingsNotes?.data, (item) => (
          <Box key={item._id}>
            <HStack mb="5px">
              <Box flex="1">
                <Heading size="sm">{item.finishedBy?.name ?? "-"}</Heading>
                <Text fontSize="xs">Reunião finalizada em {moment(item.finishedAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
              </Box>
              <Button
                as={RouterLink}
                to={`/meetings/edit/${item._id}`}
                target="_blank"
                size="xs"
                variant="outline"
                rightIcon={<Icon as={TbExternalLink} />}
              >
                abrir reunião
              </Button>
            </HStack>
            <Text fontSize="sm">{item.notes}</Text>
          </Box>
        ))}
      </VStack>

      {isLoadingMeetingsNotes ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(meetingsNotes?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={PiNotepadBold} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma nota de reunião adicionada
              </Text>
              <Text fontSize="sm">Este cliente ainda não possui notas de reunião adicionadas.</Text>
            </Box>
          </Center>
        )
      )}
    </>
  );
};

export default MeetingsNotes;
