import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Select,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import * as yup from "yup";
import { messages } from "consts";
import { api } from "lib";
import { Breadcrumb, PermissionedContainer, InputCurrency, BoxData, DocumentHistory, AsyncSelect } from "components";
import { usePermissioned, useCustomToast, useDocumentTitle, useApiGet } from "hooks";
import { MdChevronLeft, MdHistory } from "react-icons/md";
import { Content } from "pages/Private/Container";

let loadTeamsTimeout, loadUsersTimeout, loadDemandsTimeout;

export const MeetingTypesDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar tipo de reunião" : "Novo tipo de reunião");
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/meeting-types/${_id}` }), [_id]));
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();
  const isAllowedUpdateTeams = usePermissioned("teams:update");

  useEffect(() => {
    const formData = data ?? { isEnabledTaskCreation: true, isActive: true };
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/meeting-types/${_id}`, data) : await api.put("/meeting-types", data);
        navigate(`/settings/records/meeting-types/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        teams: yup.array().min(1, messages.error.required).required(messages.error.required),
        users: yup.array().min(1, messages.error.required).required(messages.error.required),
        demands: yup.array().min(1, messages.error.required).required(messages.error.required),
        informedAvgDurationInMinutes: yup.number().required(messages.error.required),
      });
      const data = {
        ...formData,
        teams: _.map(formData.teams, "_id"),
        users: _.map(formData.users, "_id"),
        demands: _.map(formData.demands, "_id"),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleLoadTeams = useCallback((search, cb) => {
    clearTimeout(loadTeamsTimeout);
    loadTeamsTimeout = setTimeout(async () => {
      const response = await api.post("/teams", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
        withoutPopulate: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.post("/users", {
        search,
        query: { isActive: true },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadDemands = useCallback((search, cb) => {
    clearTimeout(loadDemandsTimeout);
    loadDemandsTimeout = setTimeout(async () => {
      const response = await api.post("/demands", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/settings#records", label: "cadastros" },
                { to: "/settings/records/meeting-types", label: "tipos de reunião" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Tipo de reunião</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={{ base: 4, lg: 2 }}>
            <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 8, lg: 10 }}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors.teams}>
              <HStack mb="0.5em" justifyContent="space-between">
                <FormLabel fontSize="sm" m="0">
                  Times
                </FormLabel>
                <PermissionedContainer required="teams:create">
                  <Link as={RouterLink} to={`/settings/records/teams/new`} target="_blank" color="blue.500" fontSize="xs">
                    incluir cadastro
                  </Link>
                </PermissionedContainer>
              </HStack>
              <AsyncSelect
                isMulti
                value={formData.teams ?? []}
                defaultOptions
                loadOptions={handleLoadTeams}
                placeholder="Selecione"
                onChange={(teams) => setFormData((state) => ({ ...state, teams }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ title }) => title}
                components={{
                  MultiValueLabel: ({ data: { _id, title } }) => (
                    <Link as={isAllowedUpdateTeams && RouterLink} to={`/settings/records/teams/edit/${_id}`} target="_blank">
                      {title}
                    </Link>
                  ),
                }}
              />
              <FormErrorMessage>{formErrors.users}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors.users}>
              <FormLabel fontSize="sm">Usuários</FormLabel>
              <AsyncSelect
                isMulti
                value={formData.users ?? []}
                defaultOptions
                loadOptions={handleLoadUsers}
                placeholder="Selecione"
                onChange={(users) => setFormData((state) => ({ ...state, users }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ avatarUrl, name }) => (
                  <HStack>
                    <Avatar size="xs" name={name} src={avatarUrl} />
                    <Text>{name}</Text>
                  </HStack>
                )}
              />
              <FormErrorMessage>{formErrors.users}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors.demands}>
              <FormLabel fontSize="sm">Demandas</FormLabel>
              <AsyncSelect
                isMulti
                value={formData.demands ?? []}
                defaultOptions
                loadOptions={handleLoadDemands}
                placeholder="Selecione"
                onChange={(demands) => setFormData((state) => ({ ...state, demands }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ title }) => title}
              />
              <FormErrorMessage>{formErrors.demands}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.informedAvgDurationInMinutes}>
              <FormLabel fontSize="sm">Duração média informada</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.informedAvgDurationInMinutes ?? ""}
                  onChange={(informedAvgDurationInMinutes) => setFormData((state) => ({ ...state, informedAvgDurationInMinutes }))}
                />
                <InputRightAddon fontSize="sm">minutos</InputRightAddon>
              </InputGroup>
              <FormErrorMessage>{formErrors.informedAvgDurationInMinutes}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <BoxData
              label="Duração média calculada"
              value={`${formData.calculatedAvgDurationInMinutes?.toLocaleString() ?? "-"} minutos`}
            />
          </GridItem>

          <GridItem colSpan={{ base: 8, lg: 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors.isEnabledTaskCreation}>
              <FormLabel fontSize="sm">Habilitar criação de tarefas?</FormLabel>
              <Select
                value={formData.isEnabledTaskCreation ? "yes" : "no"}
                onChange={({ target }) => setFormData((state) => ({ ...state, isEnabledTaskCreation: target.value === "yes" }))}
              >
                <option value="yes">Sim</option>
                <option value="no">Não</option>
              </Select>
              <FormErrorMessage>{formErrors.isEnabledTaskCreation}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Content>

      <PermissionedContainer required={"meeting-types:".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && <DocumentHistory path={`/meeting-types/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </>
  );
};
