import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AsyncSelect, CustomerLogo, InputCurrency, RangeDatePicker, RangeInput, SyncSelect } from "components";
import { useCacheState, useNewTabState, useStickyState } from "hooks";
import { translator, api } from "lib";
import { statuses } from "consts";
import { MdClose, MdRefresh, MdSearch } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

let loadCustomersTimeout, loadTeamsTimeout, loadMeetingRoomsTimeout;
let loadDemandsTimeout = {},
  loadMeetingTypesTimeout = {},
  loadUsersTimeout = {};

const placements = [
  { value: "external", label: "Externo" },
  { value: "internal_remote", label: "Interno remoto" },
  { value: "internal_in_person", label: "Interno presencial" },
];

const Filters = ({ onQuery, onRefresh, onPage, isLoading, timestampData, onTableDrawer }) => {
  const location = useLocation();
  const newTabState = useNewTabState();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: newTabState ?? location.state ?? { status: statuses.meetings.filter((o) => o.value === "unscheduled") },
        useCached: _.isObject(newTabState) === false && _.isObject(location.state) === false,
        _v: 1,
        processor: (data) => ({
          ...data,
          startDate: data.startDate && moment(data.startDate).toDate(),
          endDate: data.endDate && moment(data.endDate).toDate(),
          createdAtStartDate: data.createdAtStartDate && moment(data.createdAtStartDate).toDate(),
          createdAtEndDate: data.createdAtEndDate && moment(data.createdAtEndDate).toDate(),
        }),
      }),
      [location.pathname, location.state, newTabState]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    const toObjectId = (data) => _.map(data, (o) => ["@ObjectId", o._id]);
    if (query.nid?.length) _.set(response, `nid`, parseInt(query.nid));
    if (query.status?.length) _.set(response, `status.$in`, _.map(query.status, "value"));
    if (query.customer?._id) _.set(response, `["customer._id"]`, ["@ObjectId", query.customer._id]);
    if (query.customer?.length) _.set(response, `["customer._id"].$in`, toObjectId(query.customer));
    if (query.classification?.length) _.set(response, `["customer.classification"]`, query.classification);
    if (query.meetingRoom?.length) {
      const $in = toObjectId(query.meetingRoom);
      response.$or = [{ "mainMeetingRoom._id": { $in } }, { "participants.meetingRoom._id": { $in } }];
    }
    if (query.meetingType?.length) _.set(response, `["meetingType._id"].$in`, toObjectId(query.meetingType));
    if (query.meetingTypeExcept?.length) _.set(response, `["meetingType._id"].$nin`, toObjectId(query.meetingTypeExcept));
    if (query.demands?.length) _.set(response, `["demands._id"].$in`, toObjectId(query.demands));
    if (query.demandsExcept?.length) _.set(response, `["demands._id"].$nin`, toObjectId(query.demandsExcept));
    if (query.participants?.length) _.set(response, `["participants.user._id"].$in`, toObjectId(query.participants));
    if (query.team?.length) _.set(response, `["meetingType.teams._id"].$in`, toObjectId(query.team));
    if (query.startDate) _.set(response, `startDate.$gte`, ["@ISODate", moment(query.startDate).startOf("day").toDate()]);
    if (query.endDate) _.set(response, `endDate.$lte`, ["@ISODate", moment(query.endDate).endOf("day").toDate()]);
    if (query.createdAtStartDate) _.set(response, `createdAt.$gte`, ["@ISODate", query.createdAtStartDate]);
    if (query.createdAtEndDate) _.set(response, `createdAt.$lte`, ["@ISODate", query.createdAtEndDate]);
    if (query.placement?.length) _.set(response, `placement.$in`, _.map(query.placement, "value"));
    if (query.isDelayed?.length) _.set(response, `isDelayed`, query.isDelayed === "yes");
    if (query.durationInMinutesStart) _.set(response, "durationInMinutes.$gte", query.durationInMinutesStart);
    if (query.durationInMinutesEnd) _.set(response, "durationInMinutes.$lte", query.durationInMinutesEnd);
    if (query.startedBy?.length) _.set(response, `["startedBy._id"].$in`, toObjectId(query.startedBy));
    if (query.finishedBy?.length) _.set(response, `["finishedBy._id"].$in`, toObjectId(query.finishedBy));
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query]);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { status: { $ne: "inactive" } },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/customers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadMeetingType = useCallback((key, search, cb) => {
    clearTimeout(loadMeetingTypesTimeout[key]);
    loadMeetingTypesTimeout[key] = setTimeout(async () => {
      const response = await api.post("/meeting-types", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadTeams = useCallback((search, cb) => {
    clearTimeout(loadTeamsTimeout);
    loadTeamsTimeout = setTimeout(async () => {
      const response = await api.post("/teams", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((key, search, cb) => {
    clearTimeout(loadUsersTimeout[key]);
    loadUsersTimeout[key] = setTimeout(async () => {
      const response = await api.post("/users", {
        search,
        query: { isActive: true },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadDemands = useCallback((key, search, cb) => {
    clearTimeout(loadDemandsTimeout[key]);
    loadDemandsTimeout[key] = setTimeout(async () => {
      const response = await api.post("/demands", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadMeetingRooms = useCallback((search, cb) => {
    clearTimeout(loadMeetingRoomsTimeout);
    loadMeetingRoomsTimeout = setTimeout(async () => {
      const response = await api.post("/meeting-rooms", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={8}>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 12 }}>
          <HStack>
            <Button
              variant="outline"
              colorScheme={isFiltering ? "main" : "gray"}
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <Text fontSize="xs">
              Atualizado em <strong>{moment(timestampData).format("DD/MM/YYYY")}</strong> às{" "}
              <strong>{moment(timestampData).format("HH:mm:ss")}</strong>
            </Text>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 1 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.nid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.meetings}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cliente
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customer ?? []}
                  defaultOptions
                  loadOptions={handleLoadCustomers}
                  placeholder="Selecione"
                  onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                    <HStack>
                      <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                      <Box flex="1">
                        <Text fontSize="sm" noOfLines={1}>
                          {tradingName}
                        </Text>
                        <Text fontSize="xs">{segment?.title || "-"}</Text>
                      </Box>
                    </HStack>
                  )}
                  isDisabled={_.isObject(location.state?.customer)}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Classificação
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.classification ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, classification: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tipo de reunião
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.meetingType ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadMeetingType("meetingType", search, cb)}
                  placeholder="Selecione"
                  onChange={(meetingType) => setFormData((state) => ({ ...state, meetingType }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tipo de reunião (exceto)
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.meetingTypeExcept ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadMeetingType("meetingTypeExcept", search, cb)}
                  placeholder="Selecione"
                  onChange={(meetingTypeExcept) => setFormData((state) => ({ ...state, meetingTypeExcept }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Time
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.team ?? []}
                  defaultOptions
                  loadOptions={handleLoadTeams}
                  placeholder="Selecione"
                  onChange={(team) => setFormData((state) => ({ ...state, team }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Participantes
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.participants ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("participants", search, cb)}
                  placeholder="Selecione"
                  onChange={(participants) => setFormData((state) => ({ ...state, participants }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ avatarUrl, name }) => (
                    <HStack>
                      <Avatar size="xs" name={name} src={avatarUrl} />
                      <Text>{name}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Demandas
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.demands ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadDemands("demands", search, cb)}
                  placeholder="Selecione"
                  onChange={(demands) => setFormData((state) => ({ ...state, demands }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Demandas (exceto)
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.demandsExcept ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadDemands("demandsExcept", search, cb)}
                  placeholder="Selecione"
                  onChange={(demandsExcept) => setFormData((state) => ({ ...state, demandsExcept }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Sala de reunião
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.meetingRoom ?? []}
                  defaultOptions
                  loadOptions={handleLoadMeetingRooms}
                  placeholder="Selecione"
                  onChange={(meetingRoom) => setFormData((state) => ({ ...state, meetingRoom }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Atrasado?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isDelayed ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isDelayed: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Data do agendamento
                </FormLabel>
                <HStack spacing="2px">
                  <RangeDatePicker
                    key={formData.startDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.startDate, formData.endDate]}
                    onDateChange={([startDate, endDate]) => setFormData((state) => ({ ...state, startDate, endDate }))}
                  />
                  {formData.startDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          startDate: null,
                          endDate: null,
                          startDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <HStack>
                  <RangeDatePicker
                    key={formData.createdAtStartDateTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    selectedDates={[formData.createdAtStartDate, formData.createdAtEndDate]}
                    onDateChange={([createdAtStartDate, createdAtEndDate]) =>
                      setFormData((state) => ({ ...state, createdAtStartDate, createdAtEndDate }))
                    }
                  />
                  {formData.createdAtStartDate && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStartDate: null,
                          createdAtEndDate: null,
                          createdAtStartDateTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Local
                </FormLabel>
                <SyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.placement ?? []}
                  placeholder="Selecione"
                  options={placements}
                  onChange={(placement) => setFormData((state) => ({ ...state, placement }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Duração
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  precision="0"
                  defaultStartValue={formData.durationInMinutesStart}
                  defaultEndValue={formData.durationInMinutesEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({ ...state, durationInMinutesStart: startValue, durationInMinutesEnd: endValue }))
                  }
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Iniciada por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.startedBy ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("startedBy", search, cb)}
                  placeholder="Selecione"
                  onChange={(startedBy) => setFormData((state) => ({ ...state, startedBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ avatarUrl, name }) => (
                    <HStack>
                      <Avatar size="xs" name={name} src={avatarUrl} />
                      <Text>{name}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Finalizada por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.finishedBy ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("finishedBy", search, cb)}
                  placeholder="Selecione"
                  onChange={(finishedBy) => setFormData((state) => ({ ...state, finishedBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ avatarUrl, name }) => (
                    <HStack>
                      <Avatar size="xs" name={name} src={avatarUrl} />
                      <Text>{name}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
