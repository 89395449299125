import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    main: {
      50: "#FCF2E8",
      100: "#F8DABF",
      200: "#F3C196",
      300: "#EEA96C",
      400: "#EA9143",
      500: "#E5791A",
      600: "#B76115",
      700: "#894910",
      800: "#5C310A",
      900: "#2E1805",
    },
    orangeRed: {
      50: "#FFECE5",
      100: "#FFCBB8",
      200: "#FFA98A",
      300: "#FF885C",
      400: "#FF662E",
      500: "#FF4500",
      600: "#CC3700",
      700: "#992900",
      800: "#661C00",
      900: "#330E00",
    },
  },
});
