import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdCheck, MdChevronLeft, MdHistory, MdOutlineCancel } from "react-icons/md";
import { yup, api } from "lib";
import { messages } from "consts";
import { Breadcrumb, DocumentHistory, PermissionedContainer } from "components";
import { usePermissioned, useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { TbPlayerPause } from "react-icons/tb";
import { VscDebugStart } from "react-icons/vsc";
import { Content } from "pages/Private/Container";
import { AppContext } from "AppProvider";
import General from "./general";
import Pauses from "./pauses";
import Files from "./files";
import TasksDetailsContext from "./context";
import StatusChange from "./statusChange";
import ActiveNotes from "./activeNotes";
import Reset from "./reset";
import MeetingsNotes from "./meetingsNotes";

export const TasksDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar tarefa" : "Novo tarefa");
  const navigate = useNavigate();
  const location = useLocation();
  const { isTabModeV } = useContext(AppContext);
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/tasks/${_id}` }), [_id]));
  const [counters, setCounters] = useState({});
  const [isLoadingCounters, setIsLoadingCounters] = useState({});
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const { isOpen: isOpenNewConfirm, onOpen: onOpenNewConfirm, onClose: onCloseNewConfirm } = useDisclosure();
  const [formDataTimestamp, setFormDataTimestamp] = useState(Date.now());
  const isAllowedAction = usePermissioned("tasks:".concat(_id ? "update" : "create"));
  const [tabIndex, setTabIndex] = useState(0);
  const statusChangeRef = useRef();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? {
      status: "pending",
      team: null,
      responsible: null,
      demand: null,
      customer: location.state?.customer,
      referenceDate: location.state?.referenceDate ?? moment().startOf("month").toDate(),
      dueDate: location.state?.dueDate ?? moment().endOf("month").toDate(),
      meeting: location.state?.meeting,
    };
    if (formData.referenceDate) formData.referenceDate = moment(formData.referenceDate).format("MM/YYYY");
    if (formData.dueDate) formData.dueDate = moment(formData.dueDate).toDate();
    if (formData.startedAt) formData.startedAt = moment(formData.startedAt).format("DD/MM/YYYY HH:mm");
    if (formData.finishedAt) formData.finishedAt = moment(formData.finishedAt).format("DD/MM/YYYY HH:mm");
    if (formData.canceledAt) formData.canceledAt = moment(formData.canceledAt).format("DD/MM/YYYY HH:mm");
    if (_.size(formData.pauses) >= 1)
      formData.pauses = _.map(formData.pauses, (pause) => ({
        ...pause,
        startedAt: pause.startedAt && moment(pause.startedAt).format("DD/MM/YYYY HH:mm"),
        finishedAt: pause.finishedAt && moment(pause.finishedAt).format("DD/MM/YYYY HH:mm"),
      }));
    setFormData(formData);
    setFormErrors({});
  }, [data, formDataTimestamp, location.state?.customer, location.state?.referenceDate, location.state?.dueDate, location.state?.meeting]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/tasks/${_id}`, data) : await api.put("/tasks", data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        navigate(`/tasks/edit/${saved._id}`, { replace: true });
        refreshData();
        if (location.state?.customer) onOpenNewConfirm();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, location.state?.customer, toast, navigate, onOpenNewConfirm]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = {
        ...formData,
        customer: formData.customer?._id,
        team: formData.team?._id,
        responsible: formData.responsible?._id,
        demand: formData.demand?._id,
        referenceDate: moment(formData.referenceDate, "MM/YYYY").toDate(),
        startedAt: moment(formData.startedAt, "DD/MM/YYYY HH:mm").toDate(),
        finishedAt: moment(formData.finishedAt, "DD/MM/YYYY HH:mm").toDate(),
        canceledAt: moment(formData.canceledAt, "DD/MM/YYYY HH:mm").toDate(),
        pauses: _.map(formData.pauses, (pause) => ({
          ...pause,
          startedAt: moment(pause.startedAt, "DD/MM/YYYY HH:mm").toDate(),
          finishedAt: moment(pause.finishedAt, "DD/MM/YYYY HH:mm").toDate(),
        })),
        meeting: formData.meeting?._id ?? null,
      };

      const isFinishedAtValid = function (finishedAt) {
        return this.parent.startedAt <= finishedAt;
      };

      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        customer: yup.string().required(messages.error.required),
        team: yup.string().required(messages.error.required),
        responsible: yup.string().required(messages.error.required),
        demand: yup.string().required(messages.error.required),
        referenceDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        dueDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        description: yup.string().required(messages.error.required),
        startedAt: formData.startedBy && yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        finishedAt:
          formData.finishedBy &&
          yup
            .date()
            .typeError(messages.error.invalidDate)
            .required(messages.error.required)
            .test("is-finished-at-valid", messages.error.endDateLowerThanEndDate, isFinishedAtValid),
        canceledAt: formData.canceledBy && yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        pauses: yup.array().of(
          yup.object().shape({
            startedAt: yup.date().typeError(messages.error.invalidDate).required(messages.error.required).isValidPauseStartedAt(data),
            finishedAt:
              formData.status !== "paused" &&
              yup
                .date()
                .typeError(messages.error.invalidDate)
                .required(messages.error.required)
                .test("is-finished-at-valid", messages.error.endDateLowerThanEndDate, isFinishedAtValid)
                .isValidPauseFinishedAt(data),
            notes: yup.string().required(messages.error.required).isValidNotes(),
          })
        ),
      });

      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleNewConfirm = useCallback(() => {
    navigate("/tasks/new", { state: { customer: formData.customer, dueDate: formData.dueDate }, replace: true });
    onCloseNewConfirm();
    setFormDataTimestamp(Date.now());
  }, [formData.customer, formData.dueDate]);

  const renderActionButtons = useCallback(() => {
    switch (data?.status) {
      case "pending":
        return (
          <HStack>
            <PermissionedContainer required="tasks:cancel">
              <Button
                size="sm"
                variant="ghost"
                colorScheme="red"
                rightIcon={<Icon as={MdOutlineCancel} />}
                onClick={() => statusChangeRef.current.open("canceled")}
              >
                cancelar
              </Button>
            </PermissionedContainer>
            <Button
              size="sm"
              variant="solid"
              colorScheme="green"
              rightIcon={<Icon as={VscDebugStart} />}
              onClick={() => statusChangeRef.current.open("started")}
            >
              iniciar
            </Button>
          </HStack>
        );
      case "started":
        return (
          <HStack>
            <Button
              size="sm"
              variant="solid"
              colorScheme="gray"
              rightIcon={<Icon as={TbPlayerPause} />}
              onClick={() => statusChangeRef.current.open("paused")}
            >
              pausar
            </Button>
            <Button
              size="sm"
              variant="solid"
              colorScheme="blue"
              rightIcon={<Icon as={MdCheck} />}
              onClick={() => statusChangeRef.current.open("finished")}
            >
              finalizar
            </Button>
          </HStack>
        );
      case "paused":
        return (
          <Button
            size="sm"
            variant="solid"
            colorScheme="green"
            rightIcon={<Icon as={VscDebugStart} />}
            onClick={() => statusChangeRef.current.open("resumed")}
          >
            retomar
          </Button>
        );
    }
  }, [data?.status]);

  return (
    <TasksDetailsContext.Provider
      value={{
        data,
        isLoadingData,
        refreshData,
        formData,
        setFormData,
        formErrors,
        isAllowedAction,
        counters,
        setCounters,
        setIsLoadingCounters,
        setTabIndex,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/tasks/list", label: "tarefas" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <HStack>
            <Reset />
            {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Tarefa</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
        </HStack>
        <Tabs colorScheme="main" index={tabIndex} onChange={(index) => setTabIndex(index)}>
          <TabList onChan>
            <Tab>dados gerais</Tab>
            <Tab>
              <HStack>
                <Text>pausas</Text>
                {isLoadingData ? (
                  <Spinner size="xs" />
                ) : (
                  <Text fontSize="sm" fontWeight="semibold">
                    ({_.size(formData.pauses) ?? 0})
                  </Text>
                )}
              </HStack>
            </Tab>
            {isTabModeV && (
              <Tab>
                <HStack>
                  <Text>arquivos</Text>
                  {isLoadingCounters.files ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({counters.files ?? 0})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
            {isTabModeV && (
              <Tab>
                <HStack>
                  <Text>notas de reunião</Text>
                  {isLoadingCounters.meetingsNotes ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({counters.meetingsNotes ?? 0})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Pauses />
            </TabPanel>
            {isTabModeV && (
              <TabPanel px="0" py="30px">
                <Files />
              </TabPanel>
            )}
            {isTabModeV && (
              <TabPanel px="0" py="30px">
                <MeetingsNotes />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px" justifyContent="space-between">
          <HStack>
            {isAllowedAction && (
              <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
                salvar
              </Button>
            )}
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
          {renderActionButtons()}
        </HStack>
      </SlideFade>

      <StatusChange ref={statusChangeRef} />

      {_id && <DocumentHistory path={`/tasks/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}

      <AlertDialog isOpen={isOpenNewConfirm} closeOnEsc={false} closeOnOverlayClick={false} isCentered scrollBehavior="inside">
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>
            <Text>Deseja criar outra tarefa para este mesmo cliente?</Text>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={() => navigate(-1)}>
              não
            </Button>
            <Button size="sm" colorScheme="main" onClick={handleNewConfirm}>
              sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <ActiveNotes />
    </TasksDetailsContext.Provider>
  );
};
