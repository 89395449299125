import React, { createContext, memo, useContext, useMemo } from "react";
import { Center, Divider, HStack, SimpleGrid, Spinner, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";

const WeekContext = createContext();

const Column = memo(({ date, items, isToday }) => {
  const { renderItem, isLoading } = useContext(WeekContext);

  return (
    <VStack
      spacing="0"
      borderWidth="1px"
      borderRadius="md"
      alignItems="stretch"
      _light={{ bg: "white", color: "gray.800" }}
      _dark={{ bg: "gray.800", color: "white" }}
    >
      <HStack justifyContent="center" p="10px" spacing="4px">
        <Text fontSize="lg">Semana </Text>
        <Center
          _light={{ bg: isToday ? "main.500" : "gray.100" }}
          _dark={{ bg: isToday ? "main.500" : "gray.700" }}
          borderRadius="full"
          w="35px"
          h="35px"
        >
          <Text fontSize="lg" color={isToday && "white"}>
            {moment(date).week()}
          </Text>
        </Center>
      </HStack>
      <Divider />
      <VStack flex="1" p="2px" spacing="2px" alignItems="stretch" minHeight="60px">
        {isLoading ? (
          <Center h="100%">
            <Spinner size="xs" />
          </Center>
        ) : (
          items.map(renderItem)
        )}
      </VStack>
    </VStack>
  );
});

export const GridViewerWeek = ({ dateKey, startDate, endDate, data, renderItem, isLoading }) => {
  const columns = useMemo(() => {
    const dates = [];
    const tmp = [...(data ?? [])];
    for (let date = moment(startDate); date.isBefore(endDate); date.add(1, "week")) {
      const items = _.remove(tmp, (o) => date.isSame(moment(o[dateKey]), "week"));
      dates.push({ date: moment(date).toDate(), isToday: date.isSame(moment(), "week"), items });
    }
    return dates;
  }, [dateKey, startDate, endDate, data]);

  return (
    <WeekContext.Provider value={{ renderItem, isLoading }}>
      <SimpleGrid columns={{ base: 1, lg: 5 }} spacing="5px" overflow="hidden">
        {columns.map((column) => (
          <Column key={column.date.toISOString()} date={column.date} items={column.items} isToday={column.isToday} />
        ))}
      </SimpleGrid>
    </WeekContext.Provider>
  );
};
