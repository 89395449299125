import React, { useState, useEffect, useMemo, useCallback, useContext, Fragment } from "react";
import _ from "lodash";
import * as yup from "yup";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Link,
  Select,
  SlideFade,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { messages, paymentMethods } from "consts";
import {
  AsyncSelect,
  BoxData,
  Breadcrumb,
  CustomerLogo,
  DocumentHistory,
  InputCurrency,
  PermissionedContainer,
  SyncSelect,
} from "components";
import { api, currency, translator } from "lib";
import { usePermissioned, useApiGet, useCustomToast, useDocumentTitle, useArrayItemHandlers } from "hooks";
import { MdAdd, MdChevronLeft, MdDelete, MdHistory, MdRefresh } from "react-icons/md";
import { Content } from "pages/Private/Container";
import { PrivateContext } from "pages/Private";
import InputMask from "react-input-mask";
import moment from "moment";
import ObjectID from "bson-objectid";
import { useCorrelationKeys } from "../useCorrelationKeys";
import { TbExternalLink } from "react-icons/tb";

let loadEntitiesTimeout = {};
let loadAccountsTimeout = {};
let loadCostCentersTimeout = {};

const entityRefs = { Customer: "customers", Supplier: "suppliers", User: "users" };

export const BillsDetails = () => {
  const { documentTitle, newDocumentTitle } = useCorrelationKeys();
  const { _id, type } = useParams();
  useDocumentTitle((_id ? "Editar " : "Novo ").concat(newDocumentTitle.toLowerCase()));
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(PrivateContext);
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/bills/${type}/${_id}` }), [type, _id]));
  const [_companies, isLoadingCompanies, refreshCompanies] = useApiGet(
    useMemo(() => ({ path: `/companies`, params: { query: { isActive: true } }, sort: { name: 1 } }), [])
  );
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const isAllowedAction = usePermissioned(`bills:${type}:${_id ? "update" : "create"}`);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();
  const companies = useMemo(() => {
    const ids = _.map(formData.allocation, (o) => o.company?._id);
    return _.filter(_companies?.data, (o) => ids.indexOf(o._id) === -1);
  }, [_companies?.data, formData.allocation]);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const installmentAmount = useMemo(() => formData.amount / formData.installmentCount || 0, [formData.amount, formData.installmentCount]);
  const { isOpen: isOpenSubmitDialog, onOpen: onOpenSubmitDialog, onClose: onCloseSubmitDialog } = useDisclosure();

  useEffect(() => {
    const formData = data ?? {
      type,
      category: "variable",
      status: "pending",
      entityRef: "Customer",
      amount: 0,
      installmentCount: 1,
      currentInstallment: 1,
    };
    if (formData.referenceDate) formData.referenceDate = moment(formData.referenceDate).format("MM/YYYY");
    if (formData.dueDate) formData.dueDate = moment(formData.dueDate).format("DD/MM/YYYY");
    if (formData.paidAt) formData.paidAt = moment(formData.paidAt).format("DD/MM/YYYY");
    if (_.size(formData.allocation) === 0) formData.allocation = [{ _id: ObjectID().toHexString(), percentage: 1 }];
    setFormData(formData);
  }, [type, data, currentUser]);

  useEffect(() => {
    setFormData((state) => ({ ...state, allocation: _.map(state.allocation, (o) => ({ ...o, amount: state.amount * o.percentage })) }));
  }, [formData.amount]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/bills/${type}/${_id}`, data) : await api.put(`/bills/${type}`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        navigate(`/bills/${type}/edit/${saved._id}`, { replace: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [type, _id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      onCloseSubmitDialog();
      const allocationSchema = yup.object().shape({
        company: yup.string().required(messages.error.required),
        chartOfAccount: yup.string().required(messages.error.required),
        costCenter: yup.string().required(messages.error.required),
        percentage: yup.number().required(messages.error.required).moreThan(0, `${messages.error.moreThan} 0%.`),
      });
      let schema = yup.object().shape({
        status: yup.string().required(messages.error.required),
        description: yup.string().required(messages.error.required),
        entity: yup.string().required(messages.error.required),
        referenceDate: yup.date().typeError(messages.error.required).required(messages.error.required),
        dueDate: yup.date().typeError(messages.error.required).required(messages.error.required),
        paymentMethod: yup.string().required(messages.error.required),
        amount: yup.number().required(messages.error.required).moreThan(0, `${messages.error.moreThan} R$0,00.`),
        allocation: yup.array().of(allocationSchema).min(1, messages.error.required),
        installmentCount: yup.number().required(messages.error.required).moreThan(0, `${messages.error.moreThan} 0.`),
      });
      if (formData.status === "finished")
        schema = schema.concat(
          yup.object().shape({
            paidAt: yup.date().typeError(messages.error.required).required(messages.error.required),
          })
        );
      const data = {
        ...formData,
        entity: formData.entity?._id || null,
        referenceDate: moment(formData.referenceDate, "MM/YYYY").toDate(),
        dueDate: moment(formData.dueDate, "DD/MM/YYYY").toDate(),
        paidAt: formData.paidAt ? moment(formData.paidAt, "DD/MM/YYYY").toDate() : null,
        allocation: _.map(formData.allocation, (allocation) => ({
          ...allocation,
          company: allocation.company?._id || null,
          chartOfAccount: allocation.chartOfAccount?._id || null,
          costCenter: allocation.costCenter?._id || null,
        })),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, onCloseSubmitDialog, handleSaveData]);

  const handleSubmitConfirm = useCallback(() => {
    if (formData.status === "finished") onOpenSubmitDialog();
    else handleSubmit();
  }, [formData.status, onOpenSubmitDialog, handleSubmit]);

  const handleLoadEntities = useCallback(
    (search, cb) => {
      if (formData.entityRef) {
        clearTimeout(loadEntitiesTimeout[formData.entityRef]);
        loadEntitiesTimeout[formData.entityRef] = setTimeout(async () => {
          const query = formData.entityRef === "User" ? { isActive: true } : { status: { $ne: "inactive" } };
          const sort = formData.entityRef === "User" ? { name: 1 } : { tradingName: 1 };
          const params = { search, query, sort, perPage: 20, isAutocomplete: true };
          const response = await api.post(`/${entityRefs[formData.entityRef]}`, params);
          cb(response?.data ?? []);
        }, 1000);
      } else cb([]);
    },
    [formData.entityRef]
  );

  const handleLoadAccounts = useCallback((key, search, cb) => {
    clearTimeout(loadAccountsTimeout[key]);
    loadAccountsTimeout[key] = setTimeout(async () => {
      const params = {
        search,
        query: { detailing: "analytical", isActive: true },
        sort: { code: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      if (/\d+\./.test(search)) params.query.code = search;
      else params.search = search;
      const response = await api.post("/accounts", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadCostCenters = useCallback((key, search, cb) => {
    clearTimeout(loadCostCentersTimeout[key]);
    loadCostCentersTimeout[key] = setTimeout(async () => {
      const response = await api.post("/cost-centers", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Content>
        <HStack>
          <HStack spacing={{ base: "10px", lg: "20px" }} flex="1">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: `/bills/${type}`, label: documentTitle.toLowerCase() },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {formData.source && (
            <Button
              size="sm"
              variant="outline"
              as={RouterLink}
              to={`/meetings/edit/${formData.source._id}`}
              target="_blank"
              rightIcon={<Icon as={TbExternalLink} />}
            >
              reunião de origem #{formData.source.nid}
            </Button>
          )}
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">{newDocumentTitle}</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.description : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={{ base: 4, lg: 2 }}>
            <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 8, lg: 2 }}>
            <FormControl isRequired={true} isInvalid={formErrors.category}>
              <FormLabel fontSize="sm">Categoria</FormLabel>
              <Select
                value={formData.category ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, category: target.value, installmentCount: 1 }))}
                isDisabled={_.isString(_id)}
              >
                <option value="fixed">Fixo</option>
                <option value="variable">Variável</option>
              </Select>
              <FormErrorMessage>{formErrors.category}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 8, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.status}>
              <FormLabel fontSize="sm">Status</FormLabel>
              <Select
                value={formData.status ?? ""}
                onChange={({ target }) =>
                  setFormData((state) => ({
                    ...state,
                    status: target.value,
                    paidAt: target.value === "finished" ? moment().format("DD/MM/YYYY") : null,
                  }))
                }
              >
                <option value="">--Selecione</option>
                <option value="pending">Pagamento pendente</option>
                {_id && (
                  <Fragment>
                    <option value="waiting_approval">Aguardando boleto</option>
                    <option value="finished">Pago</option>
                  </Fragment>
                )}
              </Select>
              <FormErrorMessage>{formErrors.status}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 5 }}>
            <FormControl isRequired={true} isInvalid={formErrors.description}>
              <FormLabel fontSize="sm">Descrição</FormLabel>
              <Input
                value={formData.description ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
              />
              <FormErrorMessage>{formErrors.description}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 8, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.entityRef}>
              <FormLabel fontSize="sm">Tipo de entidade</FormLabel>
              <Select
                value={formData.entityRef ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, entityRef: target.value, entity: null }))}
              >
                <option value="Customer">Cliente</option>
                <option value="Supplier">Fornecedor</option>
                <option value="User">Usuário</option>
              </Select>
              <FormErrorMessage>{formErrors.entityRef}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.entity}>
              <HStack mb="0.5em" justifyContent="space-between">
                <FormLabel fontSize="sm" m="0">
                  Entidade
                </FormLabel>
                {formData.entity ? (
                  <PermissionedContainer required={`${entityRefs[formData.entityRef]}:update`}>
                    <Link
                      as={RouterLink}
                      to={`/${entityRefs[formData.entityRef]}/edit/${formData.entity._id}`}
                      target="_blank"
                      fontSize="xs"
                    >
                      editar cadastro
                    </Link>
                  </PermissionedContainer>
                ) : (
                  <PermissionedContainer required={`${entityRefs[formData.entityRef]}:create`}>
                    <Link as={RouterLink} to={`/${entityRefs[formData.entityRef]}/new`} target="_blank" color="blue.500" fontSize="xs">
                      incluir cadastro
                    </Link>
                  </PermissionedContainer>
                )}
              </HStack>
              <AsyncSelect
                key={formData.entityRef}
                value={formData.entity}
                defaultOptions
                loadOptions={handleLoadEntities}
                placeholder="Selecione"
                selectedOptionStyle="check"
                onChange={(entity) => setFormData((state) => ({ ...state, entity, address: null }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ logoUrl, avatarUrl, tradingName, name, segment }) =>
                  formData.entityRef === "User" ? (
                    <HStack>
                      <Avatar size="xs" name={name} src={avatarUrl} />
                      <Text flex="1">{name}</Text>
                    </HStack>
                  ) : (
                    <HStack>
                      <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                      <Box flex="1">
                        <Text fontSize="sm" noOfLines={1}>
                          {tradingName || name}
                        </Text>
                        <Text fontSize="xs">{segment?.title || "-"}</Text>
                      </Box>
                    </HStack>
                  )
                }
                isClearable={true}
              />
              <FormErrorMessage>{formErrors.entity}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 8, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.paymentMethod}>
              <FormLabel fontSize="sm">Método de pagamento</FormLabel>
              <Select
                value={formData.paymentMethod ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, paymentMethod: target.value }))}
              >
                <option value="">--Selecione</option>
                {paymentMethods.map((value) => (
                  <option value={value}>{translator(value)}</option>
                ))}
              </Select>
              <FormErrorMessage>{formErrors.paymentMethod}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.referenceDate}>
              <FormLabel fontSize="sm">Data de referência</FormLabel>
              <Input
                as={InputMask}
                mask="99/9999"
                value={formData.referenceDate ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, referenceDate: target.value }))}
              />
              <FormErrorMessage>{formErrors.referenceDate}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.dueDate}>
              <FormLabel fontSize="sm">Data de vencimento</FormLabel>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.dueDate ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, dueDate: target.value }))}
              />
              <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.paidAt}>
              <FormLabel fontSize="sm">Data de pagamento</FormLabel>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.paidAt ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, paidAt: target.value }))}
                isDisabled={formData.status !== "finished"}
              />
              <FormErrorMessage>{formErrors.paidAt}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <BoxData
              label="Finalização"
              value={
                formData.finishedAt ? (
                  <Box>
                    <Text fontSize="xs">{moment(formData.finishedAt).format("DD/MM/YYYY")}</Text>
                    <Text fontSize="xs" fontWeight="light">
                      {formData.finishedBy?.name || "-"}
                    </Text>
                  </Box>
                ) : (
                  "-"
                )
              }
            />
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.amount}>
              <FormLabel fontSize="sm">Valor</FormLabel>
              <InputGroup>
                <InputLeftAddon fontSize="sm">R$</InputLeftAddon>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.amount ?? ""}
                  onChange={(amount) => setFormData((state) => ({ ...state, amount }))}
                />
              </InputGroup>
              <FormErrorMessage>{formErrors.amount}</FormErrorMessage>
            </FormControl>
          </GridItem>
          {_id ? (
            <Fragment>
              <GridItem colSpan={{ base: 12, lg: 6 }}>
                <BoxData
                  label="Parcelamento"
                  value={
                    <Text>
                      {formData.currentInstallment} de {formData.installmentCount}
                    </Text>
                  }
                />
              </GridItem>
            </Fragment>
          ) : (
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl isRequired={true} isInvalid={formErrors.installmentCount}>
                <FormLabel fontSize="sm">Quantidade de parcelas</FormLabel>
                <InputGroup>
                  <Input
                    as={InputCurrency}
                    precision="0"
                    value={formData.installmentCount ?? ""}
                    onChange={(installmentCount) => setFormData((state) => ({ ...state, installmentCount }))}
                  />
                  <InputRightAddon fontSize="sm">x de {currency.format(installmentAmount)}</InputRightAddon>
                </InputGroup>
                <FormErrorMessage>{formErrors.installmentCount}</FormErrorMessage>
              </FormControl>
            </GridItem>
          )}

          <GridItem colSpan={{ base: 12, lg: 12 }}>
            <FormControl isInvalid={formErrors.nfSerialNumber}>
              <FormLabel fontSize="sm">Número NF</FormLabel>
              <Input
                value={formData.nfSerialNumber ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, nfSerialNumber: target.value }))}
              />
              <FormErrorMessage>{formErrors.nfSerialNumber}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Divider my={8} />

        <HStack mb={4}>
          <Box flex="1">
            <Heading size="sm">Rateio</Heading>
            <Text fontSize="xs">Informe as empresas e suas respectiras porcentagens no rateio.</Text>
            {_.isString(formErrors.allocation) && (
              <Text fontSize="sm" color="red.500">
                {formErrors.allocation}
              </Text>
            )}
          </Box>
          <Button
            size="sm"
            variant="outline"
            rightIcon={<Icon as={MdAdd} />}
            onClick={() => handleAddArrayItem("allocation", { _id: ObjectID().toHexString(), percentage: 0 })}
          >
            adicionar empresa
          </Button>
        </HStack>

        <VStack alignItems="stretch">
          {_.map(formData.allocation, (item, index) => (
            <Grid key={item._id} templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 8, lg: 2 }}>
                <FormControl isRequired={true} isInvalid={formErrors.allocation?.[index]?.company}>
                  <FormLabel fontSize="sm">Empresa</FormLabel>
                  <HStack>
                    <SyncSelect
                      value={item.company}
                      options={companies}
                      placeholder="Selecione"
                      onChange={(company) =>
                        handleChangeArrayItem("allocation", index, { company, percentage: company.defaultAllocationPercentage || 0 })
                      }
                      getOptionValue={({ _id }) => _id}
                      formatOptionLabel={({ tradingName }) => tradingName}
                      isClearable={true}
                    />
                    <IconButton
                      variant="outline"
                      icon={<Icon as={MdRefresh} />}
                      isLoading={isLoadingCompanies}
                      onClick={refreshCompanies}
                    />
                  </HStack>
                  <FormErrorMessage>{formErrors.allocation?.[index]?.company}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 8, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.allocation?.[index]?.chartOfAccount}>
                  <FormLabel fontSize="sm">Plano de contas</FormLabel>
                  <AsyncSelect
                    defaultOptions
                    placeholder="Selecione"
                    value={item.chartOfAccount}
                    loadOptions={(search, cb) => handleLoadAccounts(index.toString(), search, cb)}
                    onChange={(chartOfAccount) => handleChangeArrayItem("allocation", index, { chartOfAccount })}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ code, name }) => `${code} - ${name}`}
                    isClearable={true}
                  />
                  <FormErrorMessage>{formErrors.allocation?.[index]?.chartOfAccount}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 8, lg: 3 }}>
                <FormControl isRequired={true} isInvalid={formErrors.allocation?.[index]?.costCenter}>
                  <FormLabel fontSize="sm">Centro de custo</FormLabel>
                  <AsyncSelect
                    defaultOptions
                    placeholder="Selecione"
                    value={item.costCenter}
                    loadOptions={(search, cb) => handleLoadCostCenters(index.toString(), search, cb)}
                    onChange={(costCenter) => handleChangeArrayItem("allocation", index, { costCenter })}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ title }) => title}
                    isClearable={true}
                  />
                  <FormErrorMessage>{formErrors.allocation?.[index]?.costCenter}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 8, lg: 2 }}>
                <FormControl isRequired={true} isInvalid={formErrors.allocation?.[index]?.percentage}>
                  <FormLabel fontSize="sm">Porcentagem</FormLabel>
                  <InputGroup>
                    <Input
                      as={InputCurrency}
                      precision="2"
                      value={item.percentage}
                      onChange={(percentage) =>
                        handleChangeArrayItem("allocation", index, { percentage, amount: formData.amount * percentage })
                      }
                      isPercentage={true}
                    />
                    <InputRightAddon fontSize="sm">%</InputRightAddon>
                  </InputGroup>
                  <FormErrorMessage>{formErrors.allocation?.[index]?.percentage}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 8, lg: 2 }}>
                <FormControl isRequired={true} isInvalid={formErrors.allocation?.[index]?.amount}>
                  <FormLabel fontSize="sm">Valor</FormLabel>
                  <HStack>
                    <InputGroup>
                      <InputLeftAddon fontSize="sm">R$</InputLeftAddon>
                      <Input
                        as={InputCurrency}
                        precision="2"
                        value={item.amount}
                        onChange={(amount) => handleChangeArrayItem("allocation", index, { amount, percentage: amount / formData.amount })}
                      />
                    </InputGroup>
                    <IconButton
                      variant="outline"
                      icon={<Icon as={MdDelete} />}
                      onClick={() => handleDeleteArrayItem("allocation", index)}
                    />
                  </HStack>
                  <FormErrorMessage>{formErrors.allocation?.[index]?.amount}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          ))}
        </VStack>

        <Divider my={8} />

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={{ base: 12, lg: 12 }}>
            <FormControl isInvalid={formErrors.comments}>
              <FormLabel fontSize="sm">Observações</FormLabel>
              <Textarea
                minH="200px"
                value={formData.comments ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
              />
              <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px" justifyContent="space-between">
          <HStack>
            {(!_id || isAllowedAction) && !formData.finishedAt && (
              <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmitConfirm}>
                salvar
              </Button>
            )}
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </HStack>
      </SlideFade>

      {_id && <DocumentHistory path={`/bills/${type}/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}

      <AlertDialog isOpen={isOpenSubmitDialog} onClose={onCloseSubmitDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente finalizar o documento atual? Esta ação é irreversível.</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseSubmitDialog}>cancelar</Button>
            <Button colorScheme="green" onClick={handleSubmit}>
              confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
