import { Fragment, useCallback } from "react";
import _ from "lodash";
import { HStack, Icon, IconButton, Input, Text } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";

export const InputChips = ({ size, value, onChange }) => {
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const [input] = e.target;
      const tmp = _.filter(value, (o) => o.name !== input.value);
      if (input.value.length === 0) return;
      tmp.push({ name: input.value });
      e.target.reset();
      onChange(tmp);
    },
    [value]
  );

  const handleRemove = useCallback(
    (index) => {
      const tmp = [...value];
      tmp.splice(index, 1);
      onChange(tmp);
    },
    [value]
  );

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Input size={size} placeholder="Pressione 'Enter' após a digitação de cada nome" />
      </form>
      <HStack mt={2}>
        {_.map(value, (o, index) => (
          <HStack
            key={index.toString()}
            _light={{ bg: "gray.50" }}
            _dark={{ bg: "gray.900" }}
            px="10px"
            py="4px"
            borderWidth="1px"
            borderRadius="md"
          >
            <Text fontSize="xs">{o.name}</Text>
            <IconButton size="xs" variant="outline" icon={<Icon as={MdClose} />} onClick={handleRemove.bind(this, index)} />
          </HStack>
        ))}
      </HStack>
    </Fragment>
  );
};
