import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdChevronLeft, MdHistory } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { Breadcrumb, DocumentHistory } from "components";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { Content } from "pages/Private/Container";
import General from "./general";
import Files from "./files";
import CustomerIndicatorsDetailsContext from "./context";
import { TbCloudUpload } from "react-icons/tb";
import Import from "./import";
import { PermissionedContainer } from "components";
import AppProvider from "AppProvider";

export const CustomerIndicatorsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar indicadores" : "Novo indicadores");
  const navigate = useNavigate();
  const location = useLocation();
  const { isTabModeV } = useContext(AppProvider);
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/customer-indicators/${_id}` }), [_id]));
  const [indicatorTypes, isLoadingIndicatorTypes] = useApiGet(
    useMemo(
      () => ({
        path: "/indicator-types",
        params: { query: { isActive: true }, sort: { title: 1 } },
      }),
      []
    )
  );
  const [files, isLoadingFiles, refreshFiles] = useApiGet(
    useMemo(
      () => ({
        path: `/files`,
        params: { query: { owner: _id }, perPage: -1 },
        options: { isEnabled: !!_id },
      }),
      [_id]
    )
  );
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenImport, onOpen: onOpenImport, onClose: onCloseImport } = useDisclosure();
  const { isOpen: isOpenSubmitConfirmDialog, onOpen: onOpenSubmitConfirmDialog, onClose: onCloseSubmitConfirmDialog } = useDisclosure();
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const isClosing = useMemo(() => formData.status === "closed", [formData.status]);
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? {
      status: "pending",
      referenceDate: moment().toDate(),
      periodType: "month",
      periodStartDate: moment().startOf("month").toDate(),
      periodEndDate: moment().endOf("month").toDate(),
      indicators: [],
    };
    if (formData.referenceDate) formData.referenceDate = moment(formData.referenceDate).format("DD/MM/YYYY");
    if (formData.periodStartDate) formData.periodStartDate = moment(formData.periodStartDate).format("DD/MM/YYYY");
    if (formData.periodEndDate) formData.periodEndDate = moment(formData.periodEndDate).format("DD/MM/YYYY");
    _.forEach(indicatorTypes?.data, (indicatorType) => {
      const index = _.findIndex(formData.indicators, (o) => o.indicatorType._id === indicatorType._id);
      if (index === -1) formData.indicators.push({ indicatorType, value: 0 });
    });
    setFormData(formData);
  }, [data, indicatorTypes?.data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/customer-indicators/${_id}`, data) : await api.put("/customer-indicators", data);
        navigate(`/customer-indicators/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      onCloseSubmitConfirmDialog();
      const schema = yup.object().shape({
        status: yup.string().required(messages.error.required),
        customer: yup.string().required(messages.error.required),
        referenceDate: yup.date().typeError(messages.error.invalidDate),
        periodType: yup.string().required(messages.error.invalidDate),
        indicators: yup.array().of(
          yup.object().shape({
            value: yup.string().required(messages.error.required),
          })
        ),
      });
      const data = {
        ...formData,
        customer: formData.customer?._id,
        referenceDate: formData.referenceDate && moment(formData.referenceDate, "DD/MM/YYYY").startOf("day").toDate(),
        periodStartDate: formData.periodStartDate && moment(formData.referenceDate, "DD/MM/YYYY").startOf(formData.periodType).toDate(),
        periodEndDate: formData.periodEndDate && moment(formData.referenceDate, "DD/MM/YYYY").endOf(formData.periodType).toDate(),
        indicators: _.map(formData.indicators, (o) => ({ indicatorType: o.indicatorType._id, value: o.value })),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData, onCloseSubmitConfirmDialog]);

  const handleSubmitConfirm = useCallback(() => {
    if (isClosing) return onOpenSubmitConfirmDialog();
    return handleSubmit();
  }, [isClosing, handleSubmit, onOpenSubmitConfirmDialog]);

  return (
    <CustomerIndicatorsDetailsContext.Provider
      value={{
        formData,
        setFormData,
        formErrors,
        files,
        isLoadingFiles,
        refreshFiles,
        indicatorTypes,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/customer-indicators", label: "indicadores" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Indicadores</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            {formData._id ? (
              <Text fontSize="sm">
                {formData.periodStartDate} - {formData.periodEndDate}
              </Text>
            ) : (
              <Text fontSize="sm">Novo cadastro</Text>
            )}
          </Box>
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={TbCloudUpload} />} onClick={onOpenImport}>
              importar indicadores
            </Button>
          </HStack>
        </HStack>
        <Tabs colorScheme="main">
          <TabList>
            <Tab>dados gerais</Tab>
            {isTabModeV && (
              <Tab>
                <HStack>
                  <Text>arquivos</Text>
                  {isLoadingFiles ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({_.size(files?.data)})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            {isTabModeV && (
              <TabPanel px="0" py="30px">
                <Files />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Content>

      <PermissionedContainer required={"customer-indicators:".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button
              size="sm"
              colorScheme="main"
              isLoading={isLoadingData || isLoadingIndicatorTypes || isLoadingSaveData}
              onClick={handleSubmitConfirm}
              isDisabled={formData.isClosed}
            >
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      <Import isOpen={isOpenImport} onClose={onCloseImport} />

      <AlertDialog isOpen={isOpenSubmitConfirmDialog} onClose={onCloseSubmitConfirmDialog} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Atenção
            </AlertDialogHeader>
            <AlertDialogBody>
              Esta é uma ação irreversível. Você está realizando o fechamento destes indicadores. Deseja realmente continuar?
            </AlertDialogBody>
            <AlertDialogFooter as={HStack}>
              <Button onClick={onCloseSubmitConfirmDialog}>cancelar</Button>
              <Button colorScheme="yellow" onClick={handleSubmit}>
                continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {_id && (
        <DocumentHistory path={`/customer-indicators/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
      )}
    </CustomerIndicatorsDetailsContext.Provider>
  );
};
