import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, HStack, Icon, Input, Text } from "@chakra-ui/react";
import { api, translator, yup } from "lib";
import { useStickyState } from "hooks";
import { messages, priorities, frequencies } from "consts";
import { MdSearch } from "react-icons/md";
import ReactInputMask from "react-input-mask";
import { AsyncSelect, SyncSelect } from "components";

let loadMeetingTypesTimeout;

const mappedFrequencies = frequencies.map((value) => ({ value, label: translator(value) }));

const Filters = ({ onFilters, isLoading }) => {
  const location = useLocation();
  const [filters, setFilters] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters"),
        defaultValue: {
          baseDate: moment().format("MM/YYYY"),
          startDate: moment().startOf("month").toDate(),
          endDate: moment().endOf("month").format(),
        },
        useCached: true,
        _v: 4,
        processor: (data) => ({
          ...data,
          startDate: data.startDate && moment(data.startDate).toDate(),
          endDate: data.endDate && moment(data.endDate).toDate(),
        }),
      }),
      [location.pathname]
    )
  );
  const [formData, setFormData] = useState(filters);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    onFilters(filters);
  }, [filters]);

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        startDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        endDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      });
      const data = {
        ...formData,
        startDate: moment(formData.baseDate, "MM/YYYY").startOf("month").toDate(),
        endDate: moment(formData.baseDate, "MM/YYYY").endOf("month").toDate(),
      };
      await schema.validate(data, { abortEarly: false });
      setFilters(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [setFilters, formData]);

  const handleLoadMeetingType = useCallback((search, cb) => {
    clearTimeout(loadMeetingTypesTimeout);
    loadMeetingTypesTimeout = setTimeout(async () => {
      const response = await api.post("/meeting-types", {
        search,
        filters: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <Box
      mb={8}
      padding={{ base: "10px", lg: "20px" }}
      _light={{ bg: "gray.50" }}
      _dark={{ bg: "gray.900" }}
      borderRadius="lg"
      transition="400ms"
    >
      <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={4}>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.startDate || formErrors.endDate}>
            <FormLabel fontSize="xs" mb="5px">
              Data base
            </FormLabel>
            <Input
              as={ReactInputMask}
              mask="99/9999"
              size="sm"
              variant="filled"
              value={formData.baseDate || ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, baseDate: target.value }))}
            />
            <FormErrorMessage>{formErrors.startDate || formErrors.endDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl>
            <FormLabel fontSize="xs" mb="5px">
              Prioridade
            </FormLabel>
            <SyncSelect
              size="sm"
              variant="filled"
              isMulti
              value={formData.priority ?? []}
              placeholder="Selecione"
              options={priorities.dashboardsScheduling}
              onChange={(priority) => setFormData((state) => ({ ...state, priority }))}
              formatOptionLabel={({ color, value }) => (
                <HStack>
                  <Box bg={color} w="10px" h="10px" borderRadius="full" />
                  <Text>{translator(value)}</Text>
                </HStack>
              )}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl>
            <FormLabel fontSize="xs" mb="5px">
              Frequência
            </FormLabel>
            <SyncSelect
              size="sm"
              variant="filled"
              isMulti
              value={formData.frequency ?? []}
              placeholder="Selecione"
              options={mappedFrequencies}
              onChange={(frequency) => setFormData((state) => ({ ...state, frequency }))}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl>
            <FormLabel fontSize="xs" mb="5px">
              Tipo de reunião
            </FormLabel>
            <AsyncSelect
              size="sm"
              variant="filled"
              isMulti
              value={formData.meetingType ?? []}
              defaultOptions
              loadOptions={handleLoadMeetingType}
              placeholder="Selecione"
              onChange={(meetingType) => setFormData((state) => ({ ...state, meetingType }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.classification}>
            <FormLabel fontSize="xs" mb="5px">
              Classificação
            </FormLabel>
            <Input
              size="sm"
              variant="filled"
              value={formData.classification || ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, classification: target.value }))}
            />
            <FormErrorMessage>{formErrors.classification}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
      <HStack justifyContent="flex-end">
        <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
          aplicar
        </Button>
      </HStack>
    </Box>
  );
};

export default Filters;
