import React, { useContext, useRef, useMemo, Fragment, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import { Logo } from "components";
import { currency, formatMinutes } from "lib";
import { MdClose, MdPrint } from "react-icons/md";
import ConsultationsContext from "./context";
import CustomersDetailsContext from "../context";
import { parentRefs } from ".";

const PrintDetailing = ({ isOpen, onClose }) => {
  const { formData } = useContext(CustomersDetailsContext);
  const { query, consultations } = useContext(ConsultationsContext);
  const { colorMode } = useColorMode();
  const colorScheme = useMemo(() => (colorMode === "light" ? "blackAlpha" : "whiteAlpha"[colorMode]));
  const bodyRef = useRef();
  const data = useMemo(() => _.filter(consultations?.data, (item) => item.totalDurationInMinutes >= 5));
  const balanceInMinutes = useMemo(() => _(data).map("totalDurationInMinutes").sum(), [data]);
  const travelCostAmount = useMemo(() => _(data).map("parent.travelCostAmount").sum(), [data]);

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="flex-end" position="fixed" top="0" left="0" w="100%" py="10px" px="20px">
          <ReactToPrint
            content={() => bodyRef.current}
            trigger={() => <IconButton colorScheme={colorScheme} icon={<Icon as={MdPrint} />} />}
          />
          <IconButton colorScheme={colorScheme} icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <ModalBody p="10px" ref={bodyRef}>
          <HStack borderWidth="1px" p="10px">
            <Logo width={140} />
            <Heading flex="1" textAlign="center" size="sm">
              Detalhamento de horas realizadas
            </Heading>
            <Box width="140px" textAlign="right">
              <Text fontSize="xs">Data de impressão</Text>
              <Text fontSize="xs" fontWeight="bold">
                {moment().format("DD/MM/YYYY [às] HH:mm")}
              </Text>
            </Box>
          </HStack>

          <Grid templateColumns="repeat(12, 1fr)" borderWidth="1px" p="10px" my="10px">
            <GridItem colSpan={4}>
              <Text fontSize="xs">Cliente</Text>
              <Text fontSize="xs" fontWeight="bold">
                {formData.nid?.toLocaleString()} - {formData.tradingName}
              </Text>
            </GridItem>
            <GridItem colSpan={4}>
              <Text fontSize="xs">Tempo total dos atendimentos</Text>
              <Text fontSize="xs" fontWeight="bold">
                {formatMinutes(balanceInMinutes)}
              </Text>
            </GridItem>
            <GridItem colSpan={4}>
              <Text fontSize="xs">Período</Text>
              <Text fontSize="xs" fontWeight="bold">
                {moment(query?.startDate).format("DD/MM/YYYY")} a {moment(query?.endDate).format("DD/MM/YYYY")}
              </Text>
            </GridItem>
          </Grid>

          <Box borderWidth="1px">
            <Grid templateColumns="repeat(12, 1fr)" fontSize="sm" fontWeight="semibold" textAlign="center" borderBottomWidth="1px">
              <GridItem colSpan={2} p="5px">
                <Text>Data</Text>
              </GridItem>
              <GridItem colSpan={1} p="5px">
                <Text>Início</Text>
              </GridItem>
              <GridItem colSpan={1} p="5px">
                <Text>Fim</Text>
              </GridItem>
              <GridItem colSpan={2} p="5px">
                <Text>Tempo total</Text>
              </GridItem>
              <GridItem colSpan={5} p="5px" textAlign="left">
                <Text>Consultor(es)</Text>
              </GridItem>
              <GridItem colSpan={1} p="5px">
                <Text>Despesa</Text>
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(12, 1fr)" textAlign="center" fontSize="sm">
              {_.map(data, (item) => (
                <Fragment key={item._id}>
                  <GridItem colSpan={2} p="5px">
                    {moment(item.startedAt).format("DD/MM/YYYY")}
                  </GridItem>
                  <GridItem colSpan={1} p="5px">
                    {moment(item.startedAt).format("HH:mm")}
                  </GridItem>
                  <GridItem colSpan={1} p="5px">
                    {moment(item.finishedAt).format("HH:mm")}
                  </GridItem>
                  <GridItem colSpan={2} p="5px">
                    {formatMinutes(item.totalDurationInMinutes)}
                  </GridItem>
                  <GridItem colSpan={5} p="5px" textAlign="left">
                    {_.map(item.users, "name").join(", ")}
                  </GridItem>
                  <GridItem colSpan={1} p="5px">
                    {item.parent.travelCostAmount ? currency.format(item.parent.travelCostAmount) : "-"}
                  </GridItem>
                  <GridItem colSpan={12} px="10px" pt="0" pb="5px" fontSize="xs" textAlign="left" borderBottomWidth="1px">
                    <strong>Descrição: </strong> {_.map(item.demands, "title").join(", ")} |{" "}
                    <strong>{parentRefs[item.parentRef].label}</strong>
                  </GridItem>
                </Fragment>
              ))}
            </Grid>
            <Grid templateColumns="repeat(12, 1fr)" fontSize="sm" fontWeight="bold">
              <GridItem colSpan={4} p="5px" textAlign="right">
                <Text>Tempo total dos atendimentos:</Text>
              </GridItem>
              <GridItem colSpan={2} p="5px" textAlign="center">
                {formatMinutes(balanceInMinutes)}
              </GridItem>
              <GridItem colSpan={5} p="5px" />
              <GridItem colSpan={1} p="5px">
                {currency.format(travelCostAmount)}
              </GridItem>
            </Grid>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PrintDetailing;
