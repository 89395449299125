import { useRef, useEffect, useContext } from "react";
import { PrivateContext } from "pages/Private";

export const useDocumentTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);
  const { notificationsCount } = useContext(PrivateContext);

  useEffect(() => {
    let documentTitle = [title, process.env.REACT_APP_NAME].join(" | ");
    if (notificationsCount >= 1) documentTitle = `(${notificationsCount}) ${documentTitle}`;
    document.title = documentTitle;
  }, [notificationsCount, title]);

  useEffect(() => {
    return () => {
      if (!prevailOnUnmount) document.title = defaultTitle.current;
    };
  }, []);
};
