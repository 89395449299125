import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { Breadcrumb, DocumentHistory } from "components";
import * as yup from "yup";
import { messages } from "consts";
import { PermissionedContainer } from "components";
import { Content } from "pages/Private/Container";
import { AppContext } from "AppProvider";
import General from "./general";
import Files from "./files";
import CustomersDetailsContext from "./context";
import { MdChevronLeft, MdHistory } from "react-icons/md";
import Logo from "./logo";

export const SuppliersDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar fornecedor" : "Novo fornecedor");
  const navigate = useNavigate();
  const location = useLocation();
  const { isTabModeV } = useContext(AppContext);
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/suppliers/${_id}` }), [_id]));
  const [counters, setCounters] = useState({});
  const [isLoadingCounters, setIsLoadingCounters] = useState({});
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const isActive = useMemo(() => formData.status === "active", [formData.status]);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? {
      type: "pj",
      status: "active",
      phone1Category: "cellphone",
      phone2Category: "cellphone",
      phone3Category: "cellphone",
    };
    if (_.size(formData.contacts) === 0) formData.contacts = [{ phoneCategory: "cellphone" }];
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/suppliers/${_id}`, data) : await api.put("/suppliers", data);
        navigate(`/suppliers/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        status: yup.string().required(messages.error.required),
        tradingName: yup.string().required(messages.error.required),
        document: yup.string().required(messages.error.required),
        contacts: yup.array().of(
          yup.object().shape({
            name: yup.string().required(messages.error.required),
            office: yup.string().required(messages.error.required),
            email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
          })
        ),
      });
      const data = { ...formData, contacts: _.filter(formData.contacts, ({ phoneCategory, ...rest }) => _.isEmpty(rest) === false) };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, isActive, handleSaveData]);

  return (
    <CustomersDetailsContext.Provider
      value={{
        formData,
        formErrors,
        setFormData,
        setCounters,
        setIsLoadingCounters,
        isActive,
        refreshData,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/suppliers", label: "clientes" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && (
            <HStack>
              <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />
            </HStack>
          )}
        </HStack>

        <HStack my="15px" spacing={4} justify="space-between">
          <Logo />
          <Box flex="1">
            <HStack>
              <Heading size="md">Fornecedor</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.tradingName : "Novo cadastro"}</Text>
          </Box>
        </HStack>
        <Tabs index={activeIndex} onChange={(index) => setActiveIndex(index)} colorScheme="main">
          <TabList overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
            <Tab>dados gerais</Tab>
            {isTabModeV && (
              <Tab>
                <HStack>
                  <Text>arquivos</Text>
                  {isLoadingCounters.files ? (
                    <Spinner size="xs" />
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold">
                      ({counters.files ?? 0})
                    </Text>
                  )}
                </HStack>
              </Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            {isTabModeV && (
              <TabPanel px="0" py="30px">
                <Files />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Content>

      <PermissionedContainer required={"suppliers:".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={activeIndex <= 1} hidden={activeIndex > 1} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
        <SlideFade in={activeIndex > 1} hidden={activeIndex <= 1} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && <DocumentHistory path={`/suppliers/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </CustomersDetailsContext.Provider>
  );
};
