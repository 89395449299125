import React, { createContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment-business-days";
import { Box, Center, Grid, GridItem, Heading, HStack, Icon, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { Breadcrumb } from "components";
import { useApiGet, useDocumentTitle, useMonthBusinessDays } from "hooks";
import { Content } from "pages/Private/Container";
import { percent } from "lib";
import { FiCalendar } from "react-icons/fi";
import Filters from "./filters";
import { MdRefresh } from "react-icons/md";
import ByCustomer from "./byCustomer";
import { BsQuestionCircle } from "react-icons/bs";
import ByPriority from "./byPriority";

moment.updateLocale("br", { workingWeekdays: [1, 2, 3, 4, 5] });

export const SchedulingContext = createContext();

export const Scheduling = () => {
  useDocumentTitle("Gestão de Agendas");
  const [filters, setFilters] = useState();
  const [query, setQuery] = useState();
  const [_scheduling, isLoadingScheduling, refreshScheduling] = useApiGet(
    useMemo(
      () => ({
        path: "/dashboards/scheduling",
        params: { query },
        options: { isEnabled: _.isObject(query) },
      }),
      [query]
    )
  );
  const scheduling = useMemo(() => {
    if (!filters) return _scheduling;
    const filtered = _.filter(_scheduling, (o) => {
      if (_.size(filters.priority) && _(filters.priority).map("value").includes(o.priority) === false) return false;
      if (_.size(filters.frequency) && _(filters.frequency).map("value").includes(o.frequentMeeting.frequency) === false) return false;
      if (_.size(filters.meetingType) && _(filters.meetingType).map("_id").includes(o.frequentMeeting.meetingType._id) === false)
        return false;
      if (_.size(filters.classification) && filters.classification === o.customer.classification) return false;
      return true;
    });
    return filtered;
  }, [_scheduling, filters]);

  const { businessDaysInMonth, pastBusinessDays, pastBusinessDaysPercentage } = useMonthBusinessDays(filters?.startDate);

  useEffect(() => {
    if (filters)
      setQuery((state) => {
        if (moment(state?.startDate).isSame(filters?.startDate) && moment(state?.endDate).isSame(filters?.endDate)) return state;
        return filters;
      });
  }, [filters]);

  return (
    <SchedulingContext.Provider value={{ scheduling, isLoadingScheduling, refreshScheduling }}>
      <Content>
        <HStack justify="space-between">
          <Breadcrumb items={[{ label: "gestão à vista" }, { to: "/dashboards/tasks", label: "gestão de agendas" }]} />
        </HStack>

        <HStack my="15px" justifyContent="space-between">
          <Heading size="md">Gestão de Agendas</Heading>
          <IconButton
            size="sm"
            variant="outline"
            icon={<Icon as={MdRefresh} />}
            fontSize="sm"
            isLoading={isLoadingScheduling}
            onClick={refreshScheduling}
          />
        </HStack>

        <Filters onFilters={setFilters} onRefresh={refreshScheduling} isLoading={isLoadingScheduling} />

        <Grid templateColumns="repeat(12, 1fr)" gap={2}>
          <GridItem colSpan={{ base: 12, lg: 4 }} borderWidth="1px" borderRadius="lg" p="15px">
            <HStack>
              <Center w="40px" h="40px" borderRadius="full" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }}>
                <Icon as={FiCalendar} />
              </Center>
              <Box>
                <Text fontSize="xs" whiteSpace="nowrap">
                  Último dia do mês
                </Text>
                <Heading size="sm">{moment(filters?.endDate).format("DD")}</Heading>
              </Box>
            </HStack>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }} borderWidth="1px" borderRadius="lg" p="15px">
            <HStack>
              <Center w="40px" h="40px" borderRadius="full" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }}>
                <Icon as={FiCalendar} />
              </Center>
              <Box>
                <Text fontSize="xs" whiteSpace="nowrap">
                  Dias úteis no mês
                </Text>
                <Heading size="sm">{businessDaysInMonth}</Heading>
              </Box>
            </HStack>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }} borderWidth="1px" borderRadius="lg" p="15px">
            <HStack>
              <Center w="40px" h="40px" borderRadius="full" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }}>
                <Icon as={FiCalendar} />
              </Center>
              <Box>
                <Text fontSize="xs" whiteSpace="nowrap">
                  Dias úteis corridos
                </Text>
                <Heading size="sm">
                  {pastBusinessDays} ({percent.format(pastBusinessDaysPercentage)})
                </Heading>
              </Box>
            </HStack>
          </GridItem>
        </Grid>
        <ByPriority />
        <Grid templateColumns="repeat(12, 1fr)" gap={2}>
          <GridItem colSpan={12}>
            <ByCustomer />
          </GridItem>
        </Grid>
      </Content>
    </SchedulingContext.Provider>
  );
};
