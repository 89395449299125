import React, { useContext, useState, useCallback, useMemo, useImperativeHandle, forwardRef, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import _ from "lodash";
import { api, yup } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import TasksDetailsContext from "./context";
import RecipientEmails from "./recipientEmail";

const labels = {
  started: "iniciar",
  paused: "pausar",
  resumed: "retomar",
  finished: "finalizar",
  canceled: "cancelar",
};

const StatusChange = forwardRef((props, ref) => {
  const { _id } = useParams();
  const { refreshData } = useContext(TasksDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const isNotesRequired = useMemo(() => ["paused", "finished", "canceled"].indexOf(formData.status) !== -1, [formData.status]);
  const isIrreversible = useMemo(() => formData.status !== "paused", [formData.status]);
  const toast = useCustomToast();

  const open = useCallback((status) => {
    setFormData({ status });
  }, []);

  const close = useCallback(() => {
    setFormData({});
    setFormErrors({});
  }, []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/tasks/${_id}/status`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
        close();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, close]
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (isNotesRequired) {
        const schema = yup.object().shape({ notes: yup.string().required(messages.error.required).isValidNotes() });
        await schema.validate(formData, { abortEarly: false });
      }
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [isNotesRequired, formData, handleSaveData]);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  return (
    <Fragment>
      <AlertDialog isOpen={_.isString(formData.status)} onClose={close} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>
            <Text>
              Deseja {labels[formData.status]} a tarefa? {isIrreversible && "Este é um procedimento irreversível."}
            </Text>
            {formData.status === "finished" && (
              <RecipientEmails onChange={(recipientEmails) => setFormData((state) => ({ ...state, recipientEmails }))} />
            )}
            {isNotesRequired && (
              <>
                <Divider my={4} />
                <FormControl isRequired={true} isInvalid={formErrors.notes}>
                  <FormLabel fontSize="sm">Notas</FormLabel>
                  <Textarea
                    value={formData.notes ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, notes: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.notes}</FormErrorMessage>
                </FormControl>
              </>
            )}
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={close}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
});

export default StatusChange;
