import { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import { api } from "lib";

const defaults = {
  params: {},
  options: { isNil: true, isEnabled: true },
};

export const useApiGet = ({ path, params = defaults.params, options = defaults.options }) => {
  const [response, setResponse] = useState(undefined);
  const [loading, setLoading] = useState(options.isEnabled);
  const [error, setError] = useState(null);
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    const isValidUrl = () => {
      const pieces = path.split("/");
      const filtered = _.filter(pieces, (o) => ["undefined", "null"].indexOf(o) === -1);
      if (!options.isNil) return true;
      if (pieces.length === filtered.length) return true;
      return false;
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        if (isValidUrl()) {
          const response = await api.post(path, params);
          setResponse(response);
          setError(null);
        } else setResponse(null);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    let timeout = setTimeout(() => {
      if (options.isEnabled) fetchData();
    }, 100);
    return () => clearTimeout(timeout);
  }, [path, params, options, timestamp]);

  const refresh = useCallback(() => setTimestamp(Date.now()), []);

  const fetchAllPaginatedData = useCallback(() => api.fetchAllPaginatedData(path, params), [path, params]);

  return [response, loading, refresh, error, fetchAllPaginatedData, timestamp];
};
