import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { TbCalendarTime } from "react-icons/tb";
import MeetingsDetailsContext from "../context";

const Rescheduled = () => {
  const { formData } = useContext(MeetingsDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label={`Histórico de agendamentos (${_.size(formData.rescheduled)})`}>
        <IconButton size="sm" variant="outline" icon={<Icon as={TbCalendarTime} />} onClick={onOpen} />
      </Tooltip>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Text flex="1">Histórico de horários</Text>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <VStack alignItems="stretch" spacing={4} divider={<StackDivider />}>
              {_.map(formData.rescheduled, (reschedule) => (
                <Grid key={reschedule._id} templateColumns="repeat(12, 1fr)" gap={4}>
                  <GridItem colSpan={{ base: 12, lg: 4 }}>
                    <Text fontSize="xs" fontWeight="semibold">
                      Usuário
                    </Text>
                    <Text fontSize="sm">{reschedule.createdBy?.name ?? "-"}</Text>
                  </GridItem>
                  <GridItem colSpan={{ base: 12, lg: 4 }}>
                    <Text fontSize="xs" fontWeight="semibold">
                      Nova data/horário
                    </Text>
                    <Text fontSize="sm">
                      {moment(reschedule.startDate).format("DD/MM/YYYY [das] HH:mm")} às {moment(reschedule.endDate).format("HH:mm")}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={{ base: 12, lg: 4 }}>
                    <Text fontSize="xs" fontWeight="semibold">
                      Atualizada em
                    </Text>
                    <Text fontSize="sm">{moment(reschedule.createdAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
                  </GridItem>
                </Grid>
              ))}
            </VStack>

            {_.size(formData.rescheduled) === 0 && (
              <Center paddingTop="40px" paddingBottom="20px">
                <Box textAlign="center">
                  <Icon as={TbCalendarTime} boxSize={20} marginBottom="10px" />
                  <Text fontSize="lg" fontWeight="bold">
                    Nenhum agendamento
                  </Text>
                  <Text fontSize="sm">Esta reunião ainda não possui histórico de agendamentos.</Text>
                </Box>
              </Center>
            )}
          </ModalBody>
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Rescheduled;
