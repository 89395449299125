import React, { useContext, useMemo, useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Avatar, Box, Button, Center, Grid, GridItem, HStack, Icon, IconButton, Spinner, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { useApiGet } from "hooks";
import { FileList } from "components";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdRefresh } from "react-icons/md";
import { TbCalendar, TbExternalLink } from "react-icons/tb";
import CustomersDetailsContext from "../context";
import { AppContext } from "AppProvider";

const Tasks = () => {
  const { _id } = useParams();
  const { isTabModeV } = useContext(AppContext);
  const { setCounters, setIsLoadingCounters } = useContext(CustomersDetailsContext);
  const [tasks, isLoadingTasks, refreshTasks] = useApiGet(
    useMemo(() => ({ path: `/customers/${_id}/tasks`, options: { isEnabled: _.isString(_id) } }), [_id])
  );
  const [isOpen, setIsOpen] = useState({});

  useEffect(() => {
    setCounters((state) => ({ ...state, tasks: tasks?.size ?? 0 }));
  }, [setCounters, tasks?.size]);

  useEffect(() => {
    setIsLoadingCounters((state) => ({ ...state, tasks: isLoadingTasks }));
  }, [setIsLoadingCounters, isLoadingTasks]);

  return (
    <>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(tasks?.data)} registros adicionados
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingTasks} onClick={refreshTasks} />
      </HStack>

      <VStack align="stretch" spacing={2}>
        {_.map(tasks?.data, (item) => (
          <Box key={item._id} borderWidth="1px" borderRadius="md" overflow="hidden">
            <Grid templateColumns="repeat(12, 1fr)" gap={4} p="15px" _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }}>
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <Text fontSize="xs" fontWeight="semibold">
                  Demanda
                </Text>
                <Text fontSize="sm">{item.demand?.title}</Text>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 2 }}>
                <Text fontSize="xs" fontWeight="semibold">
                  Time
                </Text>
                <Text fontSize="sm">{item.team?.title ?? "-"}</Text>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 2 }}>
                <Text fontSize="xs" fontWeight="semibold">
                  Responsável
                </Text>
                <HStack>
                  <Avatar size="xs" name={item.responsible?.name} src={item.responsible?.avatarUrl} />
                  <Text fontSize="sm">{item.responsible?.name ?? "-"}</Text>
                </HStack>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 2 }}>
                <Text fontSize="xs" fontWeight="semibold">
                  Finalizada em
                </Text>
                <Text fontSize="sm">{moment(item.finishedAt).format("DD/MM/YYYY")}</Text>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 2 }}>
                <HStack>
                  <Button
                    w="100%"
                    as={RouterLink}
                    to={`/tasks/edit/${item._id}`}
                    target="_blank"
                    size="sm"
                    variant="outline"
                    rightIcon={<Icon as={TbExternalLink} />}
                  >
                    tarefa
                  </Button>
                  <IconButton
                    size="sm"
                    icon={<Icon as={isOpen[item._id] ? MdKeyboardArrowUp : MdKeyboardArrowDown} />}
                    onClick={() => setIsOpen((state) => ({ ...state, [item._id]: !state[item._id] }))}
                  />
                </HStack>
              </GridItem>
            </Grid>
            {isTabModeV && isOpen[item._id] && _.size(item.files) >= 1 && (
              <Box p="15px">
                <FileList path="/files" data={item.files} isEditable={false} isDeletable={false} />
              </Box>
            )}
          </Box>
        ))}
      </VStack>

      {isLoadingTasks ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(tasks?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={TbCalendar} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma tarefa adicionada
              </Text>
              <Text fontSize="sm">Ainda não existem tarefas para o mês de agendamento desta reunião.</Text>
            </Box>
          </Center>
        )
      )}
    </>
  );
};

export default Tasks;
