import React, { Fragment, useState, useEffect, useMemo, useCallback, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import * as yup from "yup";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  SlideFade,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { messages } from "consts";
import { AsyncSelect, BoxData, Breadcrumb, DocumentHistory, SyncSelect, PermissionedContainer, CustomerLogo } from "components";
import { api, translator } from "lib";
import { usePermissioned, useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import { MdCalendarToday, MdChevronLeft, MdHistory } from "react-icons/md";
import { Content } from "pages/Private/Container";
import { PrivateContext } from "pages/Private";
import SolicitationsDetailsContext from "./context";
import StatusChange from "./statusChange";

let loadCustomersTimeout, loadUsersTimeout, loadMeetingTypesTimeout;

export const SolicitationsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar solicitação" : "Novo solicitação");
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(PrivateContext);
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/solicitations/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const isAllowedAction = usePermissioned("solicitations:".concat(_id ? "update" : "create"));
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { status: "pending" };
    if (formData.authorizedAt) formData.authorizedAt = moment(formData.authorizedAt).format("DD/MM/YYYY HH:mm");
    if (formData.refusedAt) formData.refusedAt = moment(formData.refusedAt).format("DD/MM/YYYY HH:mm");
    setFormData(formData);
  }, [data, currentUser]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/solicitations/${_id}`, data) : await api.put("/solicitations", data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        navigate(`/solicitations/edit/${saved._id}`, { replace: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        status: yup.string().required(messages.error.required),
        description: yup.string().required(messages.error.required),
        responsible: yup.string().required(messages.error.required),
        authorizedAt: formData.authorizedBy && yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        refusedAt: formData.refusedBy && yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      });
      const data = {
        ...formData,
        responsible: formData.responsible?._id,
        authorizedAt: moment(formData.authorizedAt, "DD/MM/YYYY HH:mm").toDate(),
        refusedAt: moment(formData.refusedAt, "DD/MM/YYYY HH:mm").toDate(),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { status: { $ne: "inactive" } },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/customers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadMeetingTypes = useCallback((search, cb) => {
    clearTimeout(loadMeetingTypesTimeout);
    loadMeetingTypesTimeout = setTimeout(async () => {
      const response = await api.post("/meeting-types", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.post("/users", {
        search,
        query: { isActive: true },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <SolicitationsDetailsContext.Provider value={{ data, isLoadingData, refreshData, formData, setFormData }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/solicitations", label: "solicitações" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Solicitação</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={{ base: 4, lg: 2 }}>
            <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 8, lg: 2 }}>
            <BoxData label="Status" value={translator(formData.status)} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isInvalid={formErrors.customer}>
              <HStack mb="0.5em" justifyContent="space-between">
                <FormLabel fontSize="sm" m="0">
                  Cliente
                </FormLabel>
                {formData.customer ? (
                  <PermissionedContainer required="customers:update">
                    <Link as={RouterLink} to={`/customers/edit/${formData.customer._id}`} target="_blank" fontSize="xs">
                      editar cadastro
                    </Link>
                  </PermissionedContainer>
                ) : (
                  <PermissionedContainer required="customers:create">
                    <Link as={RouterLink} to={`/customers/new`} target="_blank" color="blue.500" fontSize="xs">
                      incluir cadastro
                    </Link>
                  </PermissionedContainer>
                )}
              </HStack>
              <AsyncSelect
                value={formData.customer}
                defaultOptions
                loadOptions={handleLoadCustomers}
                placeholder="Selecione"
                selectedOptionStyle="check"
                onChange={(customer) => setFormData((state) => ({ ...state, customer, address: null }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                  <HStack>
                    <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                    <Box flex="1">
                      <Text fontSize="sm" noOfLines={1}>
                        {tradingName}
                      </Text>
                      <Text fontSize="xs">{segment?.title || "-"}</Text>
                    </Box>
                  </HStack>
                )}
                isClearable={true}
              />
              <FormErrorMessage>{formErrors.customer}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isInvalid={formErrors.address}>
              <FormLabel fontSize="sm">Endereço</FormLabel>
              <SyncSelect
                value={formData.address}
                options={formData.customer?.addresses}
                placeholder="Selecione"
                selectedOptionStyle="check"
                onChange={(address) => setFormData((state) => ({ ...state, address }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ title, line1, line2 }) => (
                  <Box>
                    <Text fontSize="sm">{title}</Text>
                    <Text fontSize="xs">
                      {line1} - {line2}
                    </Text>
                  </Box>
                )}
                isClearable={true}
              />
              <FormErrorMessage>{formErrors.address}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isInvalid={formErrors.meetingType}>
              <HStack mb="0.5em" justifyContent="space-between">
                <FormLabel fontSize="sm" m="0">
                  Tipo de reunião
                </FormLabel>
                {formData.meetingType ? (
                  <PermissionedContainer required="meeting-types:update">
                    <Link
                      as={RouterLink}
                      to={`/settings/records/meeting-types/edit/${formData.meetingType._id}`}
                      target="_blank"
                      fontSize="xs"
                    >
                      editar cadastro
                    </Link>
                  </PermissionedContainer>
                ) : (
                  <PermissionedContainer required="meeting-types:create">
                    <Link as={RouterLink} to={`/settings/records/meeting-types/new`} target="_blank" color="blue.500" fontSize="xs">
                      incluir cadastro
                    </Link>
                  </PermissionedContainer>
                )}
              </HStack>
              <AsyncSelect
                defaultOptions
                value={formData.meetingType}
                loadOptions={handleLoadMeetingTypes}
                placeholder="Selecione"
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ title }) => title}
                onChange={(meetingType) => setFormData((state) => ({ ...state, meetingType, responsible: null }))}
                isClearable={true}
              />
              <FormErrorMessage>{formErrors.meetingType}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isInvalid={formErrors.responsible}>
              <HStack mb="0.5em" justifyContent="space-between">
                <FormLabel fontSize="sm" m="0">
                  Responsável
                </FormLabel>
                {formData.responsible ? (
                  <PermissionedContainer required="users:update">
                    <Link as={RouterLink} to={`/users/edit/${formData.responsible._id}`} target="_blank" fontSize="xs">
                      editar cadastro
                    </Link>
                  </PermissionedContainer>
                ) : (
                  <PermissionedContainer required="users:create">
                    <Link as={RouterLink} to={`/users/new`} target="_blank" color="blue.500" fontSize="xs">
                      incluir cadastro
                    </Link>
                  </PermissionedContainer>
                )}
              </HStack>
              <AsyncSelect
                defaultOptions
                value={formData.responsible}
                loadOptions={handleLoadUsers}
                placeholder="Selecione"
                onChange={(responsible) => setFormData((state) => ({ ...state, responsible }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ avatarUrl, name }) => (
                  <HStack>
                    <Avatar size="xs" name={name} src={avatarUrl} />
                    <Text>{name}</Text>
                  </HStack>
                )}
                isClearable={true}
              />
              <FormErrorMessage>{formErrors.responsible}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 12 }}>
            <FormControl isRequired={true} isInvalid={formErrors.description}>
              <FormLabel fontSize="sm">Descrição</FormLabel>
              <Textarea
                minH="200px"
                value={formData.description ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
              />
              <FormErrorMessage>{formErrors.description}</FormErrorMessage>
            </FormControl>
          </GridItem>

          {["authorized", "refused"].map((status) => {
            const keys = { by: status.concat("By"), at: status.concat("At") };
            return (
              formData[keys.by] && (
                <Fragment key={status}>
                  <GridItem colSpan={{ base: 12, lg: 6 }}>
                    <BoxData label={`${translator(status)} por`} value={formData[keys.by]?.name ?? "-"} />
                  </GridItem>
                  <GridItem colSpan={{ base: 12, lg: 6 }}>
                    <FormControl isRequired={true} isInvalid={formErrors[keys.at]}>
                      <FormLabel fontSize="sm">{translator(status)} em</FormLabel>
                      <InputGroup>
                        <InputLeftElement>
                          <Icon as={MdCalendarToday} />
                        </InputLeftElement>
                        <Input
                          as={ReactInputMask}
                          mask="99/99/9999 99:99"
                          value={formData[keys.at] ?? ""}
                          onChange={({ target }) => setFormData((state) => ({ ...state, [keys.at]: target.value }))}
                        />
                      </InputGroup>
                      <FormErrorMessage>{formErrors[keys.at]}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </Fragment>
              )
            );
          })}

          {formData.status !== "pending" && (
            <GridItem colSpan={{ base: 12, lg: 12 }}>
              <FormControl isRequired={true} isInvalid={formErrors.notes}>
                <FormLabel fontSize="sm">Notas</FormLabel>
                <Textarea
                  minH="200px"
                  value={formData.notes ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, notes: target.value }))}
                />
                <FormErrorMessage>{formErrors.notes}</FormErrorMessage>
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <HStack p="20px" justifyContent="space-between">
          <HStack>
            {(!_id || isAllowedAction) && (
              <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
                salvar
              </Button>
            )}
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
          {data?.status === "pending" && (
            <PermissionedContainer required="meetings:update">
              <HStack>
                <StatusChange status="refused" />
                <StatusChange status="authorized" />
              </HStack>
            </PermissionedContainer>
          )}
        </HStack>
      </SlideFade>

      {_id && <DocumentHistory path={`/solicitations/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </SolicitationsDetailsContext.Provider>
  );
};
