export const meetings = [
  { color: "gray.300", value: "unscheduled" },
  { color: "orangeRed.500", value: "sent" },
  { color: "yellow.500", value: "scheduled" },
  { color: "green.500", value: "started" },
  { color: "blue.500", value: "finished" },
  { color: "purple.500", value: "canceled_by_customer" },
  { color: "purple.500", value: "canceled_by_consultant" },
  { color: "purple.500", value: "canceled_by_scheduling" },
];

export const tasks = [
  { color: "gray.300", value: "unscheduled" },
  { color: "yellow.500", value: "pending" },
  { color: "green.500", value: "started" },
  { color: "yellow.500", value: "paused" },
  { color: "blue.500", value: "finished" },
  { color: "purple.500", value: "canceled" },
];

export const solicitations = [
  { color: "yellow.500", value: "pending" },
  { color: "green.500", value: "authorized" },
  { color: "red.500", value: "refused" },
];

export const customers = [
  { color: "blue.500", value: "prospect" },
  { color: "green.500", value: "active" },
  { color: "gray.300", value: "inactive" },
];

export const customerIndicators = [
  { color: "yellow.500", value: "pending" },
  { color: "blue.500", value: "finished" },
];

export const payments = [
  { color: "yellow.500", value: "pending" },
  { color: "green.500", value: "paid" },
];

export const bills = [
  { color: "yellow.500", value: "pending" },
  { color: "blue.500", value: "waiting_approval" },
  { color: "green.500", value: "finished" },
  { color: "purple.500", value: "active" },
  { color: "gray.300", value: "inactive" },
];

export const crons = [
  { color: "orange.500", value: "in_progress" },
  { color: "gray.500", value: "finished" },
  { color: "red.500", value: "failed" },
];
