import React, { useCallback, useContext, useEffect } from "react";
import _ from "lodash";
import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
} from "@chakra-ui/react";
import { InputCurrency, BoxData, AsyncSelect } from "components";
import { translator, formatMinutes, api } from "lib";
import { paymentMethods } from "consts";
import CustomersDetailsContext from "../context";
import MinutesUsageAlert from "./minutesUsageAlert";

let loadAccountsTimeout = {},
  loadCostCentersTimeout = {};

const Preferences = () => {
  const { formData, setFormData, formErrors } = useContext(CustomersDetailsContext);

  const setPrefs = useCallback(
    (key, obj) => {
      setFormData((state) => {
        let prefs = { ...state.prefs };
        prefs[key] = { ...prefs[key], ...obj };
        return { ...state, prefs };
      });
    },
    [setFormData]
  );

  useEffect(() => {
    const minutesUsageAvailableMonthly = (() => {
      try {
        if (formData.prefs.consultations.hourPrice === 0) return 0;
        const hoursUsageAvailableMonthly = _.divide(formData.prefs.monthlyPayments.amount, formData.prefs.consultations.hourPrice) || 0;
        return hoursUsageAvailableMonthly * 60;
      } catch (error) {
        return 0;
      }
    })();
    setPrefs("consultations", { minutesUsageAvailableMonthly });
  }, [formData.prefs?.monthlyPayments?.amount, formData.prefs?.consultations?.hourPrice]);

  const handleLoadAccounts = useCallback((key, search, cb) => {
    clearTimeout(loadAccountsTimeout[key]);
    loadAccountsTimeout[key] = setTimeout(async () => {
      const params = {
        search,
        query: { detailing: "analytical", isActive: true },
        sort: { code: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      if (/\d+\./.test(search)) params.query.code = search;
      else params.search = search;
      const response = await api.post("/accounts", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadCostCenters = useCallback((key, search, cb) => {
    clearTimeout(loadCostCentersTimeout[key]);
    loadCostCentersTimeout[key] = setTimeout(async () => {
      const response = await api.post("/cost-centers", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Heading size="sm" mb={6}>
        Mensalidade
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.monthlyPayments?.paymentMethod}>
            <FormLabel fontSize="sm">Método de pagamento</FormLabel>
            <Select
              value={formData.prefs?.monthlyPayments?.paymentMethod ?? ""}
              onChange={({ target }) => setPrefs("monthlyPayments", { paymentMethod: target.value })}
            >
              <option value="">--Selecione</option>
              {paymentMethods.map((value) => (
                <option key={value} value={value}>
                  {translator(value)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formErrors.prefs?.monthlyPayments?.paymentMethod}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.monthlyPayments?.chartOfAccount}>
            <FormLabel fontSize="sm">Plano de contas</FormLabel>
            <AsyncSelect
              defaultOptions
              placeholder="Selecione"
              value={formData.prefs?.monthlyPayments?.chartOfAccount}
              loadOptions={(search, cb) => handleLoadAccounts("monthlyPayments", search, cb)}
              onChange={(chartOfAccount) => setPrefs("monthlyPayments", { chartOfAccount })}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ code, name }) => `${code} - ${name}`}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.prefs?.monthlyPayments?.chartOfAccount}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.monthlyPayments?.costCenter}>
            <FormLabel fontSize="sm">Centro de custos</FormLabel>
            <AsyncSelect
              defaultOptions
              placeholder="Selecione"
              value={formData.prefs?.monthlyPayments?.costCenter}
              loadOptions={(search, cb) => handleLoadCostCenters("monthlyPayments", search, cb)}
              onChange={(costCenter) => setPrefs("monthlyPayments", { costCenter })}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.prefs?.monthlyPayments?.costCenter}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.monthlyPayments?.amount}>
            <FormLabel fontSize="sm">Valor</FormLabel>
            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>
              <Input
                as={InputCurrency}
                value={formData.prefs?.monthlyPayments?.amount ?? ""}
                onChange={(amount) => setPrefs("monthlyPayments", { amount })}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.prefs?.monthlyPayments?.amount}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.monthlyPayments?.dueDay}>
            <FormLabel fontSize="sm">Dia de vencimento</FormLabel>
            <Input
              as={InputCurrency}
              precision="0"
              value={formData.prefs?.monthlyPayments?.dueDay ?? ""}
              onChange={(dueDay) => setPrefs("monthlyPayments", { dueDay })}
            />
            <FormErrorMessage>{formErrors.prefs?.monthlyPayments?.dueDay}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Consultoria
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.prefs?.consultations?.hourPrice}>
            <FormLabel fontSize="sm">Valor de hora ideal</FormLabel>
            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>
              <Input
                as={InputCurrency}
                value={formData.prefs?.consultations?.hourPrice ?? ""}
                onChange={(hourPrice) => setPrefs("consultations", { hourPrice })}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.prefs?.consultations?.hourPrice}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <BoxData label="Horas ideal" value={formatMinutes(formData.prefs?.consultations?.minutesUsageAvailableMonthly)} />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <BoxData
            label="Horas utilizadas neste mês"
            value={formatMinutes(formData.prefs?.consultations?.minutesUsageInCurrentMonth || 0)}
            RightComponent={<MinutesUsageAlert />}
          />
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Custo de viagem do consultor
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.prefs?.consultantTravelCosts?.paymentMethod}>
            <FormLabel fontSize="sm">Método de pagamento</FormLabel>
            <Select
              value={formData.prefs?.consultantTravelCosts?.paymentMethod ?? ""}
              onChange={({ target }) => setPrefs("consultantTravelCosts", { paymentMethod: target.value })}
            >
              <option value="">--Selecione</option>
              {paymentMethods.map((value) => (
                <option key={value} value={value}>
                  {translator(value)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formErrors.prefs?.consultantTravelCosts?.paymentMethod}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.prefs?.consultantTravelCosts?.chartOfAccount}>
            <FormLabel fontSize="sm">Plano de contas</FormLabel>
            <AsyncSelect
              defaultOptions
              placeholder="Selecione"
              value={formData.prefs?.consultantTravelCosts?.chartOfAccount}
              loadOptions={(search, cb) => handleLoadAccounts("consultantTravelCosts", search, cb)}
              onChange={(chartOfAccount) => setPrefs("consultantTravelCosts", { chartOfAccount })}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ code, name }) => `${code} - ${name}`}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.prefs?.consultantTravelCosts?.chartOfAccount}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.prefs?.consultantTravelCosts?.costCenter}>
            <FormLabel fontSize="sm">Centro de custos</FormLabel>
            <AsyncSelect
              defaultOptions
              placeholder="Selecione"
              value={formData.prefs?.consultantTravelCosts?.costCenter}
              loadOptions={(search, cb) => handleLoadCostCenters("consultantTravelCosts", search, cb)}
              onChange={(costCenter) => setPrefs("consultantTravelCosts", { costCenter })}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.prefs?.consultantTravelCosts?.costCenter}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Custo de viagem do cliente
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.prefs?.customerTravelCosts?.paymentMethod}>
            <FormLabel fontSize="sm">Método de pagamento</FormLabel>
            <Select
              value={formData.prefs?.customerTravelCosts?.paymentMethod ?? ""}
              onChange={({ target }) => setPrefs("customerTravelCosts", { paymentMethod: target.value })}
            >
              <option value="">--Selecione</option>
              {paymentMethods.map((value) => (
                <option key={value} value={value}>
                  {translator(value)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formErrors.prefs?.customerTravelCosts?.paymentMethod}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.prefs?.customerTravelCosts?.chartOfAccount}>
            <FormLabel fontSize="sm">Plano de contas</FormLabel>
            <AsyncSelect
              defaultOptions
              placeholder="Selecione"
              value={formData.prefs?.customerTravelCosts?.chartOfAccount}
              loadOptions={(search, cb) => handleLoadAccounts("customerTravelCosts", search, cb)}
              onChange={(chartOfAccount) => setPrefs("customerTravelCosts", { chartOfAccount })}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ code, name }) => `${code} - ${name}`}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.prefs?.customerTravelCosts?.chartOfAccount}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.prefs?.customerTravelCosts?.costCenter}>
            <FormLabel fontSize="sm">Centro de custos</FormLabel>
            <AsyncSelect
              defaultOptions
              placeholder="Selecione"
              value={formData.prefs?.customerTravelCosts?.costCenter}
              loadOptions={(search, cb) => handleLoadCostCenters("customerTravelCosts", search, cb)}
              onChange={(costCenter) => setPrefs("customerTravelCosts", { costCenter })}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.prefs?.customerTravelCosts?.costCenter}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
};

export default Preferences;
