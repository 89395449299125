import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { translator, api } from "lib";
import { usePermissioned, useCacheState, useStickyState } from "hooks";
import { AsyncSelect, SyncSelect, PermissionedContainer, CustomerLogo } from "components";
import { statuses } from "consts";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import moment from "moment";

let loadMeetingTypesTimeout = {};
let loadCustomersTimeout, loadUsersTimeout, loadTeamsTimeout, loadDemandsTimeout, loadMeetingRoomsTimeout;

const placements = [
  { value: "external", label: "Externo" },
  { value: "internal_remote", label: "Interno remoto" },
  { value: "internal_in_person", label: "Interno presencial" },
];

const Filters = ({ onQuery, onRefresh, timestampData, isLoading }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: {},
        useCached: true,
        _v: 1,
      }),
      [location.pathname]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );
  const isAllowedRead = usePermissioned("meetings:read");

  useEffect(() => {
    const response = {};
    const toObjectId = (data) => _.map(data, (o) => ["@ObjectId", o._id]);
    if (query.nid?.length) _.set(response, `nid`, parseInt(query.nid));
    if (query.status?.length) _.set(response, `status.$in`, _.map(query.status, "value"));
    if (query.placement?.length) _.set(response, `placement.$in`, _.map(query.placement, "value"));
    if (query.customer?.length) _.set(response, `["customer._id"].$in`, toObjectId(query.customer));
    if (query.classification?.length) _.set(response, `["customer.classification"]`, query.classification);
    if (query.meetingType?.length) _.set(response, `["meetingType._id"].$in`, toObjectId(query.meetingType));
    if (query.meetingTypeExcept?.length) _.set(response, `["meetingType._id"].$nin`, toObjectId(query.meetingTypeExcept));
    if (query.team?.length) _.set(response, `["meetingType.teams._id"].$in`, toObjectId(query.team));
    if (query.demands?.length) _.set(response, `["demands._id"].$in`, toObjectId(query.demands));
    if (query.user?.length) _.set(response, `["participants.user._id"].$in`, toObjectId(query.user));
    if (query.meetingRoom?.length) {
      const $in = toObjectId(query.meetingRoom);
      response.$or = [{ "mainMeetingRoom._id": { $in } }, { "participants.meetingRoom._id": { $in } }];
    }
    if (query.isDelayed?.length) _.set(response, `isDelayed`, query.isDelayed === "yes");
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
  }, [onQuery, query, location.state]);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { status: { $ne: "inactive" } },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/customers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadMeetingTypes = useCallback((key, search, cb) => {
    clearTimeout(loadMeetingTypesTimeout[key]);
    loadMeetingTypesTimeout[key] = setTimeout(async () => {
      const response = await api.post("/meeting-types", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadTeams = useCallback((search, cb) => {
    clearTimeout(loadTeamsTimeout);
    loadTeamsTimeout = setTimeout(async () => {
      const response = await api.post("/teams", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.post("/users", {
        search,
        query: { isActive: true },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadDemands = useCallback((search, cb) => {
    clearTimeout(loadDemandsTimeout);
    loadDemandsTimeout = setTimeout(async () => {
      const response = await api.post("/demands", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadMeetingRooms = useCallback((search, cb) => {
    clearTimeout(loadMeetingRoomsTimeout);
    loadMeetingRoomsTimeout = setTimeout(async () => {
      const response = await api.post("/meeting-rooms", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <Box {...containerProps} zIndex="10" borderRadius="lg" transition="400ms">
      <HStack>
        <Button
          colorScheme={isFiltering ? "main" : "gray"}
          variant="outline"
          rightIcon={<Icon as={HiOutlineFilter} />}
          onClick={() => setIsOpen((state) => !state)}
        >
          filtros
        </Button>
        {isFiltering && (
          <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
            limpar filtros
          </Button>
        )}
        <HStack flex="1" justify="flex-end">
          <Text fontSize="xs">
            Atualizado em <strong>{moment(timestampData).format("DD/MM/YYYY")}</strong> às{" "}
            <strong>{moment(timestampData).format("HH:mm:ss")}</strong>
          </Text>
          <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
        </HStack>
      </HStack>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={2} my={4}>
            <GridItem colSpan={{ base: 12, lg: 1 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.nid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.meetings}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text flex="1">{translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cliente
                </FormLabel>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customer ?? []}
                  defaultOptions
                  loadOptions={handleLoadCustomers}
                  placeholder="Selecione"
                  onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                    <HStack>
                      <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                      <Box flex="1">
                        <Text fontSize="sm" noOfLines={1}>
                          {tradingName}
                        </Text>
                        <Text fontSize="xs">{segment?.title || "-"}</Text>
                      </Box>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Classificação
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.classification ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, classification: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tipo de reunião
                </FormLabel>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.meetingType ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadMeetingTypes("meetingType", search, cb)}
                  placeholder="Selecione"
                  onChange={(meetingType) => setFormData((state) => ({ ...state, meetingType }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tipo de reunião (exceto)
                </FormLabel>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.meetingTypeExcept ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadMeetingTypes("meetingTypeExcept", search, cb)}
                  placeholder="Selecione"
                  onChange={(meetingTypeExcept) => setFormData((state) => ({ ...state, meetingTypeExcept }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Time
                </FormLabel>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.team ?? []}
                  defaultOptions
                  loadOptions={handleLoadTeams}
                  placeholder="Selecione"
                  onChange={(team) => setFormData((state) => ({ ...state, team }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: isAllowedRead ? 3 : 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Demandas
                </FormLabel>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.demands ?? []}
                  defaultOptions
                  loadOptions={handleLoadDemands}
                  placeholder="Selecione"
                  onChange={(demands) => setFormData((state) => ({ ...state, demands }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>
            <PermissionedContainer required="meetings:read">
              <GridItem colSpan={{ base: 12, lg: 3 }}>
                <FormControl>
                  <FormLabel fontSize="xs" mb="5px">
                    Usuários
                  </FormLabel>
                  <AsyncSelect
                    menuPortalTarget={document.body}
                    size="sm"
                    variant="filled"
                    isMulti
                    value={formData.user ?? []}
                    defaultOptions
                    loadOptions={handleLoadUsers}
                    placeholder="Selecione"
                    onChange={(user) => setFormData((state) => ({ ...state, user }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ avatarUrl, name }) => (
                      <HStack>
                        <Avatar size="xs" name={name} src={avatarUrl} />
                        <Text>{name}</Text>
                      </HStack>
                    )}
                  />
                </FormControl>
              </GridItem>
            </PermissionedContainer>
            <GridItem colSpan={{ base: 12, lg: isAllowedRead ? 3 : 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Sala de reunião
                </FormLabel>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.meetingRoom ?? []}
                  defaultOptions
                  loadOptions={handleLoadMeetingRooms}
                  placeholder="Selecione"
                  onChange={(meetingRoom) => setFormData((state) => ({ ...state, meetingRoom }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: isAllowedRead ? 3 : 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Atrasado?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isDelayed ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isDelayed: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Local
                </FormLabel>
                <SyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.placement ?? []}
                  placeholder="Selecione"
                  options={placements}
                  onChange={(placement) => setFormData((state) => ({ ...state, placement }))}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
