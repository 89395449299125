import { useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useApiGet } from "hooks";
import { translator } from "lib";
import MeetingsDetailsContext from "./context";
import { AppContext } from "AppProvider";

const ActiveNotes = () => {
  const { isTabModeV } = useContext(AppContext);
  const { formData } = useContext(MeetingsDetailsContext);
  const [notes] = useApiGet(
    useMemo(() => {
      let query;
      if (_.isObject(formData.customer) && _.isArray(formData.meetingType?.teams))
        query = {
          status: "active",
          destModule: "meetings",
          customer: formData.customer._id,
          team: { $in: _.map(formData.meetingType?.teams, (o) => o._id) },
        };
      return {
        path: `/customers/${formData.customer?._id}/notes`,
        params: { query, sort: { createdAt: -1 }, isAutocomplete: true },
        options: { isEnabled: _.isObject(query) && isTabModeV },
      };
    }, [formData.customer, formData.meetingType, isTabModeV])
  );
  const [isOpen, setIsOpen] = useState(null);

  useEffect(() => {
    if (notes)
      setIsOpen((state) => {
        if (_.isBoolean(state)) return state;
        return _.size(notes?.data) >= 1;
      });
  }, [notes?.data]);

  return (
    <AlertDialog
      isOpen={isOpen}
      scrollBehavior="inside"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => setIsOpen(false)}
      isCentered={true}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          <Heading size="sm">Atenção</Heading>
          <Text fontSize="xs" fontWeight="normal">
            Existem alertas e/ou bloqueios para o cliente atual. Por favor, verifique a informação.
          </Text>
        </AlertDialogHeader>
        <Divider />
        <AlertDialogBody as={VStack} alignItems="stretch" divider={<StackDivider />}>
          {_.map(notes?.data, (item) => (
            <Box key={item._id} py={4}>
              <HStack>
                <Text fontWeight="semibold">#{item.nid}</Text>
                <Badge variant="solid" colorScheme={item.actionType === "block" ? "red" : "yellow"}>
                  {translator(item.actionType)}
                </Badge>
              </HStack>
              <Text fontSize="xs">{item.content}</Text>
            </Box>
          ))}
        </AlertDialogBody>
        <Divider />
        <AlertDialogFooter as={HStack} justifyContent="flex-end">
          <Button size="sm" variant="outline" onClick={() => setIsOpen(false)}>
            entendi
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ActiveNotes;
