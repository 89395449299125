import React, { useContext, useCallback, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Select,
  StackDivider,
  VStack,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { useArrayItemHandlers, useApiGet } from "hooks";
import { api, translator } from "lib";
import { frequencies } from "consts";
import {
  PermissionedContainer,
  BoxData,
  EmailAutocomplete,
  PhoneInput,
  AsyncSelect,
  SyncSelect,
  InputCurrency,
  InputChips,
} from "components";
import { MdAddCircleOutline, MdOutlineDelete, MdRefresh } from "react-icons/md";
import CustomersDetailsContext from "../context";
import Addresses from "./addresses";
import Contacts from "./contacts";
import Inactivations from "./inactivations";

let loadSegmentsTimeout;

const General = () => {
  const { formData, setFormData, formErrors, isActive } = useContext(CustomersDetailsContext);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const [meetingTypes, isLoadingMeetingTypes, refreshMeetingTypes] = useApiGet(
    useMemo(
      () => ({
        path: `/meeting-types`,
        params: { query: { isActive: true }, perPage: -1, isAutocomplete: true },
      }),
      []
    )
  );

  const handleLoadSegments = useCallback((search, cb) => {
    clearTimeout(loadSegmentsTimeout);
    loadSegmentsTimeout = setTimeout(async () => {
      const response = await api.post("/segments", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 4, lg: 2 }}>
          <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
        </GridItem>
        <GridItem colSpan={{ base: 8, lg: 2 }}>
          <FormControl isRequired={true} isInvalid={formErrors.nid}>
            <FormLabel fontSize="sm">Status</FormLabel>
            <HStack>
              <Select value={formData.status} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
                <option value="prospect">Prospecto</option>
                <option value="active">Ativo</option>
                <option value="inactive">Inativo</option>
              </Select>
              <Inactivations />
            </HStack>
            <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.tradingName}>
            <FormLabel fontSize="sm">Nome</FormLabel>
            <Input
              value={formData.tradingName ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, tradingName: target.value }))}
            />
            <FormErrorMessage>{formErrors.tradingName}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={isActive} isInvalid={formErrors.companyName}>
            <FormLabel fontSize="sm">Razão social</FormLabel>
            <Input
              value={formData.companyName ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, companyName: target.value }))}
            />
            <FormErrorMessage>{formErrors.companyName}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 6, lg: 6 }}>
          <FormControl isRequired={isActive} isInvalid={formErrors.document}>
            <FormLabel fontSize="sm">CNPJ</FormLabel>
            <Input
              as={InputMask}
              mask="99.999.999/9999-99"
              value={formData.document ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
            />
            <FormErrorMessage>{formErrors.document}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 6, lg: 6 }}>
          <FormControl isInvalid={formErrors.ie}>
            <FormLabel fontSize="sm">Inscrição estadual</FormLabel>
            <Input value={formData.ie ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, ie: target.value }))} />
            <FormErrorMessage>{formErrors.ie}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.segment}>
            <HStack mb="0.5em" justifyContent="space-between">
              <FormLabel fontSize="sm" m="0">
                Segmento
              </FormLabel>
              {formData.segment ? (
                <PermissionedContainer required="segments:update">
                  <Link as={RouterLink} to={`/settings/records/segments/edit/${formData.segment._id}`} target="_blank" fontSize="xs">
                    editar cadastro
                  </Link>
                </PermissionedContainer>
              ) : (
                <PermissionedContainer required="segments:create">
                  <Link as={RouterLink} to={`/settings/records/segments/new`} target="_blank" color="blue.500" fontSize="xs">
                    incluir cadastro
                  </Link>
                </PermissionedContainer>
              )}
            </HStack>
            <AsyncSelect
              value={formData.segment}
              defaultOptions
              loadOptions={handleLoadSegments}
              placeholder="Selecione"
              onChange={(segment) => setFormData((state) => ({ ...state, segment }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.segment}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.economicGroup}>
            <FormLabel fontSize="sm">Grupo econômico</FormLabel>
            <Input
              value={formData.economicGroup ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, economicGroup: target.value }))}
            />
            <FormErrorMessage>{formErrors.economicGroup}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.classification}>
            <FormLabel fontSize="sm">Classificação</FormLabel>
            <Input
              as={InputCurrency}
              precision="0"
              value={formData.classification ?? ""}
              onChange={(classification) => setFormData((state) => ({ ...state, classification }))}
            />
            <FormErrorMessage>{formErrors.classification}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isInvalid={formErrors.contractedAt}>
            <FormLabel fontSize="sm">Data de início do contrato</FormLabel>
            <Input
              as={InputMask}
              mask="99/99/9999"
              value={formData.contractedAt ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, contractedAt: target.value }))}
            />
            <FormErrorMessage>{formErrors.contractedAt}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Contato
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 1"
            phone={formData.phone1}
            category={formData.phone1Category}
            onChangePhone={(phone1) => setFormData((state) => ({ ...state, phone1 }))}
            onChangeCategory={(phone1Category) => setFormData((state) => ({ ...state, phone1Category }))}
            isRequired={true}
            isInvalid={formErrors.phone1}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 2"
            phone={formData.phone2}
            category={formData.phone2Category}
            onChangePhone={(phone2) => setFormData((state) => ({ ...state, phone2 }))}
            onChangeCategory={(phone2Category) => setFormData((state) => ({ ...state, phone2Category }))}
            isRequired={false}
            isInvalid={formErrors.phone2}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 3"
            phone={formData.phone3}
            category={formData.phone3Category}
            onChangePhone={(phone3) => setFormData((state) => ({ ...state, phone3 }))}
            onChangeCategory={(phone3Category) => setFormData((state) => ({ ...state, phone3Category }))}
            isRequired={false}
            isInvalid={formErrors.phone3}
          />
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={formErrors.website}>
            <FormLabel fontSize="sm">WebSite</FormLabel>
            <Input
              value={formData.website ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, website: target.value }))}
            />
            <FormErrorMessage>{formErrors.website}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isRequired={isActive} isInvalid={formErrors.email}>
            <FormLabel fontSize="sm">E-mail</FormLabel>
            <EmailAutocomplete value={formData.email ?? ""} onChange={(email) => setFormData((state) => ({ ...state, email }))} />
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Addresses />

      <Divider my={8} />

      <Contacts />

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Redes sociais
      </Heading>
      <VStack align="stretch" divider={<StackDivider opacity={{ base: "1", lg: "0" }} />} spacing={{ base: "30px", lg: "10px" }}>
        {_.map(formData.socialNetworks, (contact, index) => (
          <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl isInvalid={formErrors.socialNetworks?.[index]?.name}>
                <FormLabel fontSize="sm">Nome da rede social</FormLabel>
                <Input
                  value={contact.name ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("socialNetworks", index, { name: target.value })}
                />
                <FormErrorMessage>{formErrors.socialNetworks?.[index]?.name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 7, lg: 5 }}>
              <FormControl isInvalid={formErrors.socialNetworks?.[index]?.nick}>
                <FormLabel fontSize="sm">Nick</FormLabel>
                <InputGroup>
                  <InputLeftAddon fontSize="sm">@</InputLeftAddon>
                  <Input
                    value={contact.nick ?? ""}
                    onChange={({ target }) => handleChangeArrayItem("socialNetworks", index, { nick: target.value })}
                  />
                </InputGroup>
                <FormErrorMessage>{formErrors.socialNetworks?.[index]?.nick}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 5, lg: 1 }}>
              <FormLabel fontSize="sm">&nbsp;</FormLabel>
              <Button
                rightIcon={<Icon as={MdOutlineDelete} />}
                size="md"
                variant="outline"
                onClick={() => handleDeleteArrayItem("socialNetworks", index)}
              >
                deletar
              </Button>
            </GridItem>
          </Grid>
        ))}
      </VStack>
      <Button
        size="sm"
        colorScheme="main"
        variant="ghost"
        mt={4}
        leftIcon={<Icon as={MdAddCircleOutline} />}
        onClick={() => handleAddArrayItem("socialNetworks")}
      >
        adicionar rede social
      </Button>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Reuniões frequentes
      </Heading>
      <VStack align="stretch" divider={<StackDivider opacity={{ base: "1", lg: "0" }} />} spacing={{ base: "30px", lg: "10px" }}>
        {_.map(formData.frequentMeetings, (meeting, index) => (
          <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl isRequired={true} isInvalid={formErrors.frequentMeetings?.[index]?.meetingType}>
                <HStack mb="0.5em" justifyContent="space-between">
                  <FormLabel fontSize="sm" m="0">
                    Tipo de reunião
                  </FormLabel>
                  {meeting.meetingType ? (
                    <PermissionedContainer required="meeting-types:update">
                      <Link
                        as={RouterLink}
                        to={`/settings/records/meeting-types/edit/${meeting.meetingType._id}`}
                        target="_blank"
                        fontSize="xs"
                      >
                        editar cadastro
                      </Link>
                    </PermissionedContainer>
                  ) : (
                    <PermissionedContainer required="meeting-types:create">
                      <Link as={RouterLink} to={`/settings/records/meeting-types/new`} target="_blank" color="blue.500" fontSize="xs">
                        incluir cadastro
                      </Link>
                    </PermissionedContainer>
                  )}
                </HStack>
                <HStack>
                  <SyncSelect
                    value={meeting.meetingType}
                    options={meetingTypes?.data}
                    placeholder="Selecione"
                    selectedOptionStyle="check"
                    onChange={(meetingType) => handleChangeArrayItem("frequentMeetings", index, { meetingType })}
                    getOptionValue={({ _id }) => _id}
                    getOptionLabel={({ title }) => title}
                    isClearable={true}
                  />
                  <IconButton
                    variant="outline"
                    icon={<Icon as={MdRefresh} />}
                    isLoading={isLoadingMeetingTypes}
                    onClick={refreshMeetingTypes}
                  />
                </HStack>
                <FormErrorMessage>{formErrors.frequentMeetings?.[index]?.meetingType}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 7, lg: 5 }}>
              <FormControl isRequired={true} isInvalid={formErrors.frequentMeetings?.[index]?.frequency}>
                <FormLabel fontSize="sm">Frequência</FormLabel>
                <Select
                  value={meeting.frequency ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("frequentMeetings", index, { frequency: target.value })}
                >
                  <option>--Selecione</option>
                  {frequencies.map((value) => (
                    <option key={value} value={value}>
                      {translator(value)}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formErrors.frequentMeetings?.[index]?.frequency}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 5, lg: 1 }}>
              <FormLabel fontSize="sm">&nbsp;</FormLabel>
              <Button
                rightIcon={<Icon as={MdOutlineDelete} />}
                size="md"
                variant="outline"
                onClick={() => handleDeleteArrayItem("frequentMeetings", index)}
              >
                deletar
              </Button>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 12 }}>
              <FormLabel fontSize="sm">Participantes do cliente</FormLabel>
              <InputChips
                value={meeting.participants}
                onChange={(participants) => handleChangeArrayItem("frequentMeetings", index, { participants })}
              />
            </GridItem>
          </Grid>
        ))}
      </VStack>
      <Button
        size="sm"
        colorScheme="main"
        variant="ghost"
        mt={4}
        leftIcon={<Icon as={MdAddCircleOutline} />}
        onClick={() => handleAddArrayItem("frequentMeetings")}
      >
        adicionar reunião frequente
      </Button>
    </>
  );
};

export default General;
