import moment from "moment";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { masks } from "lib";
import { translator } from "lib";
import { CustomBadge, CustomerLogo } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "logoUrl",
    title: "Logo",
    isVisible: true,
    render: ({ logoUrl, tradingName }) => <CustomerLogo alt={tradingName} src={logoUrl} />,
    exporter: ({ logoUrl }) => logoUrl || "-",
  },
  {
    accessor: "tradingName",
    title: "Nome",
    isVisible: true,
    render: ({ _id, tradingName }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {tradingName}
      </Link>
    ),
    exporter: ({ tradingName }) => tradingName,
  },
  {
    accessor: "companyName",
    title: "Razão social",
    isVisible: false,
    formatter: ({ companyName }) => companyName,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.Customers.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "document",
    title: "CPF/CNPJ",
    isVisible: true,
    formatter: ({ document }) => masks.document(document),
  },
  {
    accessor: "phone1",
    title: "Telefone 1",
    isVisible: true,
    formatter: ({ phone1 }) => masks.phone(phone1),
  },
  {
    accessor: "phone2",
    title: "Telefone 2",
    isVisible: false,
    formatter: ({ phone2 }) => masks.phone(phone2),
  },
  {
    accessor: "phone3",
    title: "Telefone 3",
    isVisible: false,
    formatter: ({ phone3 }) => masks.phone(phone3),
  },
  {
    accessor: "email",
    title: "E-mail",
    isVisible: true,
    formatter: ({ email }) => email ?? "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    isSortable: false,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
