import React, { useContext, useState, Fragment, useEffect } from "react";
import { Box, FormControl, FormLabel, Heading, HStack, Icon, Switch, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import TasksDetailsContext from "./context";
import { LuAlertCircle } from "react-icons/lu";

const RecipientEmails = ({ onChange }) => {
  const { formData } = useContext(TasksDetailsContext);
  const [recipientEmails, setRecipientEmails] = useState([]);

  useEffect(() => {
    const recipientEmails = _(formData.customer?.contacts)
      .filter((o) => o.email && _.includes(o.demands, formData.demand?._id))
      .map(({ email }) => ({ email, isAllowedSend: true }))
      .value();
    setRecipientEmails(recipientEmails);
  }, [formData.demand?._id, formData.customer?.contacts]);

  useEffect(() => {
    onChange(
      _(recipientEmails)
        .filter((o) => o.isAllowedSend)
        .map("email")
        .value()
    );
  }, [recipientEmails]);

  return recipientEmails.length >= 1 ? (
    <Fragment>
      <Text mt={4} fontSize="sm">
        Os <strong>arquivos</strong> desta tarefa <strong>serão enviados</strong> aos contatos do cliente abaixo. Desmarque os e-mails para
        os quais não deseja realizar o envio.
      </Text>
      <VStack alignItems="stretch" spacing={1} mt={4}>
        {_.map(recipientEmails, (item, index) => (
          <FormControl key={item.email} as={HStack} borderWidth="1px" p="10px" borderRadius="lg">
            <FormLabel fontSize="xs" m="0" flex="1">
              {item.email}
            </FormLabel>
            <Switch
              isChecked={item.isAllowedSend}
              onChange={() =>
                setRecipientEmails((state) => {
                  const tmp = [...state];
                  tmp[index].isAllowedSend = !tmp[index].isAllowedSend;
                  return tmp;
                })
              }
            />
          </FormControl>
        ))}
      </VStack>
    </Fragment>
  ) : (
    <HStack bg="red.500" color="white" p="20px" borderRadius="lg" mt={4}>
      <Icon as={LuAlertCircle} boxSize="30px" />
      <Box flex="1">
        <Heading size="xs">Atenção</Heading>
        <Text fontSize="xs">Este cliente não possui um contato com e-mail válido para o envio da tarefa finalizada.</Text>
      </Box>
    </HStack>
  );
};

export default RecipientEmails;
