import { memo, useMemo } from "react";
import { Badge } from "@chakra-ui/react";
import { translatorBills } from "lib";

export const Status = memo(({ status }) => {
  const colorScheme = useMemo(() => {
    switch (status) {
      case "pending":
        return "yellow";
      case "waiting_approval":
        return "blue";
      case "finished":
        return "green";
      case "active":
        return "purple";
      case "inactive":
        return "gray";
      default:
        return "gray";
    }
  }, [status]);
  return <Badge colorScheme={colorScheme}>{translatorBills(status)}</Badge>;
});
