import moment from "moment";
import _ from "lodash";
import { Avatar, AvatarGroup, HStack, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    render: ({ _id, title }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {title}
      </Link>
    ),
    exporter: ({ title }) => title,
  },
  {
    accessor: "teams",
    title: "Times",
    isVisible: true,
    isSortable: false,
    render: ({ teams }) => (
      <Text maxW="200px" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        {_.map(teams, "title").join(", ")}
      </Text>
    ),
    exporter: ({ teams }) => _.map(teams, "title").join(", "),
  },
  {
    accessor: "users",
    title: "Usuários",
    isVisible: true,
    isSortable: false,
    render: ({ users }) => (
      <HStack>
        <AvatarGroup size="xs" max={2}>
          {_.map(users, (o) => (
            <Avatar key={o._id} name={o.name} src={o.avatarUrl} />
          ))}
        </AvatarGroup>
        <Text maxW="200px" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
          {_.map(users, "name").join(", ")}
        </Text>
      </HStack>
    ),
    exporter: ({ users }) => _.map(users, "name").join(", "),
  },
  {
    accessor: "demands",
    title: "Demandas",
    isVisible: true,
    isSortable: false,
    render: ({ demands }) => (
      <Text maxW="200px" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        {_.map(demands, "title").join(", ")}
      </Text>
    ),
    exporter: ({ demands }) => _.map(demands, "title").join(", "),
  },
  {
    accessor: "informedAvgDurationInMinutes",
    title: "Duração informada",
    isVisible: true,
    formatter: ({ informedAvgDurationInMinutes }) => `${informedAvgDurationInMinutes?.toLocaleString() ?? "-"} minutos`,
  },
  {
    accessor: "calculatedAvgDurationInMinutes",
    title: "Duração calculada",
    isVisible: true,
    formatter: ({ calculatedAvgDurationInMinutes }) => `${calculatedAvgDurationInMinutes?.toLocaleString() ?? "-"} minutos`,
  },
  {
    accessor: "isEnabledTaskCreation",
    title: "Criar tarefas?",
    isVisible: true,
    formatter: ({ isEnabledTaskCreation }) => (isEnabledTaskCreation ? "Sim" : "Não"),
  },
  {
    accessor: "isActive",
    title: "Ativo?",
    isVisible: true,
    formatter: ({ isActive }) => (isActive ? "Sim" : "Não"),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    isSortable: false,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
